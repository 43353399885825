import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import {
  PointsProps,
  PointsSortProps,
  PointProps,
  CategoryCountProps,
  TimelineTitle,
  Timeline,
} from "../services/props";
import { axiosPoints } from "../services/map";
import { Descriptions } from "antd";
import { useTranslation } from "react-i18next";
import i18n from 'translation'
import { useGeolocated } from "react-geolocated";


export const usePoints = () => {
  const { t } = useTranslation();

  const [Points, setPoints] = useState<PointsProps[]>([]);
  const [PointsSort, setPointsSort] = useState<PointsSortProps[]>([]);
  const [CategoriesCount, setCategoriesCount] = useState<CategoryCountProps[]>([]);

  // const [CategoriesCount, setCategoriesCount] = useState({
  //   'Ακρόπολη': 0,
  //   'Οικισμός': 0,
  //   'Ύπαιθρος': 0
  // })

  const { isLoading, isFetching, error, refetch } = useQuery( ["points"], () => axiosPoints.getPoints(), {
      onSuccess: (data) => {
        let arrayPoints: PointsProps[] = [];
        let arrayPointsSort: PointsSortProps[] = [];
        let arrayCategoriesCount: CategoryCountProps[] = [];
        let id: string = "";
        let title: string = "";
        let thumbnail: string = "";
        let type: string = "";
        let longtitude: string = "";
        let lantitude: string = "";

        let countAcropoli: number = 0;
        let countOikismos: number = 0;
        let countYpaifros: number = 0;

        data.map((points: any) => {
          // if (points['@type'][1] !== 'dctype:Image') {

          //   for (var key of Object.keys(points)) {

          //     switch (key) {
          //       case 'o:id': id = points[key]; break;
          //       case 'o:title': title = points[key]; break;
          //       case 'thumbnail_display_urls': thumbnail = points[key]['large']; break;
          //       case 'dcterms:subject': type = points[key][0]['@value']; break;
          //       case 'dotsoft:longtitude': longtitude = points[key][0]['@value']; break;
          //       case 'dotsoft:lantitude': lantitude = points[key][0]['@value']; break;
          //     }
          //   }

          // switch (type) {
          //   case 'Ακρόπολη': countAcropoli++; break;
          //   case 'Οικισμός': countOikismos++; break;
          //   case 'Ύπαιθρος': countYpaifros++; break;
          // }

          // arrayPoints.push({
          //   id: id,
          //   title: title,
          //   thumbnail: thumbnail,
          //   type: type,
          //   longitude: longtitude,
          //   lantitude: lantitude,
          // })

          // }//  if (points['@type'][1] !== 'dctype:Image')
          type = points.category_path[0][0];
          switch (type) {
            case "Ακρόπολη":
              countAcropoli++;
              break;
            case "Acropolis":
              countAcropoli++;
              break;
            case "Οικισμός":
              countOikismos++;
              break;
            case "Settlement":
              countOikismos++;
              break;
            case "Ύπαιθρος":
              countYpaifros++;
              break;
            case "Countryside":
              countYpaifros++;
              break;
          }

          arrayPoints.push({
            id: points.id,
            title: points.name,
            thumbnail: points.main_image,
            type: points.category_path[0][0],
            longitude: points.coordinates[0].longitude,
            lantitude: points.coordinates[0].latitude,
          });
        }); // data.map

        // SORTING
        // const ArrayPoints = [3864, 3822, 3826, 3823, 3831, 3832, 3857, 3858, 3843, 3839]
        arrayPoints.map((point: any) => {
          switch (point.id) {
            case 3864:
              arrayPointsSort.push({
                mumber: point.id,
                id: point.id,
                title: point.title,
                thumbnail: point.thumbnail,
                type: point.type,
                longitude: point.longtitude,
                lantitude: point.lantitude,
              });
              break;

            case 3822:
              arrayPointsSort.push({
                mumber: point.id,
                id: point.id,
                title: point.title,
                thumbnail: point.thumbnail,
                type: point.type,
                longitude: point.longtitude,
                lantitude: point.lantitude,
              });
              break;

            case 3826:
              arrayPointsSort.push({
                mumber: point.id,
                id: point.id,
                title: point.title,
                thumbnail: point.thumbnail,
                type: point.type,
                longitude: point.longtitude,
                lantitude: point.lantitude,
              });
              break;
            case 3823:
              arrayPointsSort.push({
                mumber: point.id,
                id: point.id,
                title: point.title,
                thumbnail: point.thumbnail,
                type: point.type,
                longitude: point.longtitude,
                lantitude: point.lantitude,
              });
              break;
            case 3831:
              arrayPointsSort.push({
                mumber: point.id,
                id: point.id,
                title: point.title,
                thumbnail: point.thumbnail,
                type: point.type,
                longitude: point.longtitude,
                lantitude: point.lantitude,
              });
              break;
            case 3832:
              arrayPointsSort.push({
                mumber: point.id,
                id: point.id,
                title: point.title,
                thumbnail: point.thumbnail,
                type: point.type,
                longitude: point.longtitude,
                lantitude: point.lantitude,
              });
              break;
            case 3857:
              arrayPointsSort.push({
                mumber: point.id,
                id: point.id,
                title: point.title,
                thumbnail: point.thumbnail,
                type: point.type,
                longitude: point.longtitude,
                lantitude: point.lantitude,
              });
              break;
            case 3858:
              arrayPointsSort.push({
                mumber: point.id,
                id: point.id,
                title: point.title,
                thumbnail: point.thumbnail,
                type: point.type,
                longitude: point.longtitude,
                lantitude: point.lantitude,
              });
              break;
            case 3843:
              arrayPointsSort.push({
                mumber: point.id,
                id: point.id,
                title: point.title,
                thumbnail: point.thumbnail,
                type: point.type,
                longitude: point.longtitude,
                lantitude: point.lantitude,
              });
              break;
            case 3839:
              arrayPointsSort.push({
                mumber: point.id,
                id: point.id,
                title: point.title,
                thumbnail: point.thumbnail,
                type: point.type,
                longitude: point.longtitude,
                lantitude: point.lantitude,
              });
              break;
          }
        });

        setPointsSort(arrayPointsSort);
        // setCategoriesCount((prevState) => {
        //   return ({
        //       ...prevState,
        //       'Ακρόπολη': countAcropoli,
        //       'Οικισμός': countOikismos,
        //       'Ύπαιθρος': countYpaifros
        //     })
        // });

        arrayCategoriesCount.push(
          {
            id: 1,
            name: t("Ακρόπολη"),
            count: countAcropoli,
            imgBig: "img/categoryAcropoli.jpg",
            imgSmall: "img/acropolis_category.png",
            alt: "photo1",
          },
          {
            id: 2,
            name: t("Οικισμός"),
            count: countOikismos,
            imgBig: "img/categoryIkoismo.jpg",
            imgSmall: "img/oikismos_category.png",
            alt: "photo2",
          },
          {
            id: 3,
            name: t("Ύπαιθρος"),
            count: countYpaifros,
            imgBig: "img/categoryYpaifro.jpg",
            imgSmall: "img/Ypaithro_category.png",
            alt: "photo3",
          }
        );

        setCategoriesCount(arrayCategoriesCount);
        
        setPoints(arrayPoints);
      },

      onError: (error: any) => {
        alert(error.message);
      },
    }
  );

  return { isLoading, isFetching, error, Points, CategoriesCount, refetch };
};


export const usePoints2 = () => {

  const { coords, isGeolocationAvailable, isGeolocationEnabled } = useGeolocated({
    positionOptions: {
        enableHighAccuracy: false,
    },
    userDecisionTimeout: 100,
  });

  return { coords }
}


export const usePointsHights = () => {
  const [PointsSort, setPointsSort] = useState<PointsSortProps[]>([]);

  let i: string = "";

  const { isLoading, isFetching, error } = useQuery(
    ["pointsHights"],
    () => axiosPoints.getPointsHights(),
    {
      onSuccess: (data) => {
        let arrayPointsSort: PointsSortProps[] = [];

        data.map((points: any) => {
          arrayPointsSort.push({
            mumber: points.id,
            id: points.id,
            title: points.name,
            thumbnail: points.main_image,
            type: points.category_path[0][0],
            longitude: points.coordinates[0].longitude,
            lantitude: points.coordinates[0].latitude,
          });
        }); // data.map

        setPointsSort(arrayPointsSort);
      },

      onError: (error: any) => {
        alert(error.message);
      },
    }
  );

  return { isLoading, isFetching, error, PointsSort };
};

export const usePoint = (id: string) => {
  const [Point, setPoint] = useState<PointProps>({
    id: "",
    title: "",
    subject: "",
    type: "",
    description: "",
    mobileDescription: "",
    thumbnail: "",
    longitude: "",
    lantitude: "",
    images: [{ id: "", url: "", alt: "", descriptionImg: "" }],
    images3d: [{ id: "", url: "", alt: "" }],
    videos: [{ id: "", url: "", alt: "" }],
    bibliographic: [{ value: "" }],
  });

  const { isLoading, isFetching, error, refetch } = useQuery(
    ["point", id],
    () => axiosPoints.getPoint(id),
    {
      onSuccess: (data) => {
        // let arrayImages = []
        // let arrImages3d = []
        // let arrayVideos = []
        // let arrayBiblio = []

        // let images = data['mobilemedia:lowResImages'][0]['@value'].split('\n')
        // let imagesDescription = data['mobilemedia:lowResImages']
        // imagesDescription.shift();

        // for (let i in images) {
        //   arrayImages.push({
        //     id: 'image_' + (parseInt(i)+1),
        //     url: images[i],
        //     alt: data['o:title'] + ' ' + (parseInt(i) + 1),
        //     descriptionImg : imagesDescription[i].display_title
        //   })
        // }

        // if (data['dotmedia:browseOutsideBuildings']) {
        //   let images3d = data['dotmedia:browseOutsideBuildings'][0]['@value'].split('\n')
        //   for (let i in images3d) {
        //     arrImages3d.push({
        //       id: 'image3d_' + (parseInt(i) + 1),
        //       url: images3d[i],
        //       alt: data['o:title'] + ' ' + (parseInt(i) + 1)
        //     })
        //   }
        // }

        // for (let i in data['dotmedia:videomp4']) {
        //   arrayVideos.push({
        //     id: 'video_' +(parseInt(i)+1),
        //     url: data['dotmedia:videomp4'][i]['@value'],
        //     alt: data['dotmedia:videomp4'][i]['property_label']
        //   })
        // }

        // for (let i in data['dcterms:bibliographicCitation']) {
        //   arrayBiblio.push({
        //     value: data['dcterms:bibliographicCitation'][i]['@value']
        //   })
        // }

        let image3d = [];
        if (data.files[6] !== undefined && data.files[6][0].url !== "")
          image3d = data.files[6][0].url;

        setPoint({
          id: data.id,
          title: data.name,
          subject: data.category_path[0][0],
          type: data.category_path[0][0],
          description: data.portal_main_info,
          mobileDescription: data.main_info,
          thumbnail: data.main_image,
          longitude: data.coordinates[0].longitude,
          lantitude: data.coordinates[0].latitude,
          images: data.files[1],
          images3d: image3d,
          videos: data.files[1],
          bibliographic: data.bibliographic_citation,
        });
      },

      onError: (error: any) => {
        alert(error.message);
      },
    }
  );

  return { isLoading, isFetching, error, Point, refetch };
};

export const usePointsTimeline = () => {
  const { t } = useTranslation();
  const [PointsTimelineTitle, setPointsTimelineTitle] = useState<TimelineTitle[]>([]);
  const [PointsTimeline, setPointsTimeline] = useState<Timeline[]>([]);
  const [allArrays, setAllArrays] = useState<any[]>([]);
  const [allArraysEn, setAllArraysEn] = useState<any[]>([]);

  const { isLoading, isFetching, error, refetch } = useQuery(["pointsTimeline"], () => axiosPoints.getPointsForTimeLine(), {
    
      onSuccess: (data) => {
        let arrayPointsTimelineTitle: TimelineTitle[] = [
          // { title: 'Γεωμετρική περίοδος, 1050 - 700 π.Χ.' },
          { title: t("Αρχαϊκή περίοδος, 700 - 480 π.Χ.") },
          { title: t("Κλασική περίοδος, 480 - 323 π.Χ.") },
          { title: t("Ελληνιστική περίοδος, 323 - 31 π.Χ.") },
          { title: t("Ρωμαϊκή αυτοκρατορική περίοδος, 31 π.Χ. - 324 μ.Χ.") },
          { title: t("Βυζαντινή περίοδος, 324 - 1309 μ.Χ.") },
          { title: t("Ιπποτική μεσαιωνική περίοδος, 1309 - 1522 μ.Χ.") },
          { title: t("Οθωμανική περίοδος, 1522 - 1912 μ.Χ.") },
        ];

        let arrayGeo: any[] = [];
        let arrayArx: any[] = [];
        let arrayKla: any[] = [];
        let arrayEll: any[] = [];
        let arrayRom: any[] = [];
        let arrayViz: any[] = [];
        let arrayYpp: any[] = [];
        let arrayOfo: any[] = [];
        let arrayAll: any[] = [];
        let arrayAllEn: any[] = [];
      
        let arrayPointsTimeline: Timeline[] = [];
        let arrayImages: any = [];
        let id: number = 0;
        let title: string = "";
        let thumbnail: string = "";
        let type: string = "";
        let longtitude: string = "";
        let lantitude: string = "";
        let date: string = "";
        let description: string = "";

        data.map((points: any) => {
          for (var key of Object.keys(points)) {
            if (key == "dcterms:date") {
              for (var dates of Object.keys(points[key])) {
                switch (points[key][dates]["@value"]) {
                  case "Γεωμετρική περίοδος, 1050 - 700 π.Χ.":
                    setArray(points[key][dates]["@value"], points, arrayGeo);
                    break;

                  case "Αρχαϊκή περίοδος, 700 - 480 π.Χ.":
                    setArray(points[key][dates]["@value"], points, arrayArx);
                    break;

                  case "Κλασική περίοδος, 480 - 323 π.Χ.":
                    setArray(points[key][dates]["@value"], points, arrayKla);
                    break;

                  case "Ελληνιστική περίοδος, 323 - 31 π.Χ.":
                    setArray(points[key][dates]["@value"], points, arrayEll);
                    break;

                  case "Ρωμαϊκή αυτοκρατορική περίοδος, 31 π.Χ. - 324 μ.Χ.":
                    setArray(points[key][dates]["@value"], points, arrayRom);
                    break;

                  case "Βυζαντινή περίοδος, 324 - 1309 μ.Χ.":
                    setArray(points[key][dates]["@value"], points, arrayViz);
                    break;

                  case "Ιπποτική μεσαιωνική περίοδος, 1309 - 1522 μ.Χ.":
                    setArray(points[key][dates]["@value"], points, arrayYpp);
                    break;

                  case "Οθωμανική περίοδος, 1522 - 1912 μ.Χ.":
                    setArray(points[key][dates]["@value"], points, arrayOfo);
                    break;
                }
              }
            }

              switch (key) {
                case "o:id":
                  id = points[key];
                  break;
                case "dcterms:title":
                  title = i18n.language == 'el' ? points[key][0]["@value"] : points[key][1] !== undefined ? points[key][1]["@value"] : '';
                  break;
                case "thumbnail_display_urls":
                  thumbnail = points[key]["large"];
                  break;
                case "dcterms:subject":
                  type = points[key][0]["@value"];
                  break;
                case "dotsoft:longtitude":
                  longtitude = points[key][0]["@value"];
                  break;
                case "dotsoft:lantitude":
                  lantitude = points[key][0]["@value"];
                  break;
                case "dcterms:date":
                  date = points[key][0]["@value"];
                  break;
                case "dotportal:mobileDescription":
                  description = i18n.language == 'el' ? points[key][0]["@value"] : points[key][1]["@value"];
                  break;
              }

          }


          arrayImages.push({
            source: { url: thumbnail },
            type: "IMAGE",
          });
          // arrayPointsTimelineTitle.push({ title: date })
          arrayPointsTimeline.push({
            id: id,
            idMitos: "",
            title: date,
            cardTitle: title,
            media: {
              name: title,
              source: { url: thumbnail },
              type: "IMAGE",
            },
            cardDetailedText: description,
          });
        });

        arrayAll.push([
          // { id: 'Timeline_1', title: 'Γεωμετρική περίοδος, 1050 - 700 π.Χ.', data: arrayGeo },
          {
            id: "Timeline_2",
            title: t("Αρχαϊκή περίοδος, 700 - 480 π.Χ."),
            data: arrayArx,
          },
          {
            id: "Timeline_3",
            title: t("Κλασική περίοδος, 480 - 323 π.Χ."),
            data: arrayKla,
          },
          {
            id: "Timeline_4",
            title: t("Ελληνιστική περίοδος, 323 - 31 π.Χ."),
            data: arrayEll,
          },
          {
            id: "Timeline_5",
            title: t("Ρωμαϊκή αυτοκρατορική περίοδος, 31 π.Χ. - 324 μ.Χ."),
            data: arrayRom,
          },
          {
            id: "Timeline_6",
            title: t("Βυζαντινή περίοδος, 324 - 1309 μ.Χ."),
            data: arrayViz,
          },
          {
            id: "Timeline_7",
            title: t("Ιπποτική μεσαιωνική περίοδος, 1309 - 1522 μ.Χ."),
            data: arrayYpp,
          },
          {
            id: "Timeline_8",
            title: t("Οθωμανική περίοδος, 1522 - 1912 μ.Χ."),
            data: arrayOfo,
          },
        ]);

        arrayAllEn.push([
          // { id: 'Timeline_1', title: 'Γεωμετρική περίοδος, 1050 - 700 π.Χ.', data: arrayGeo },
          {
            id: "Timeline_2",
            title: t("Αρχαϊκή περίοδος, 700 - 480 π.Χ."),
            data: arrayArx,
          },
          {
            id: "Timeline_3",
            title: t("Κλασική περίοδος, 480 - 323 π.Χ."),
            data: arrayKla,
          },
          {
            id: "Timeline_4",
            title: t("Ελληνιστική περίοδος, 323 - 31 π.Χ."),
            data: arrayEll,
          },
          {
            id: "Timeline_5",
            title: t("Ρωμαϊκή αυτοκρατορική περίοδος, 31 π.Χ. - 324 μ.Χ."),
            data: arrayRom,
          },
          {
            id: "Timeline_6",
            title: t("Βυζαντινή περίοδος, 324 - 1309 μ.Χ."),
            data: arrayViz,
          },
          {
            id: "Timeline_7",
            title: t("Ιπποτική μεσαιωνική περίοδος, 1309 - 1522 μ.Χ."),
            data: arrayYpp,
          },
          {
            id: "Timeline_8",
            title: t("Οθωμανική περίοδος, 1522 - 1912 μ.Χ."),
            data: arrayOfo,
          },
        ]);
      
      
      setAllArrays(arrayAll);
      setAllArraysEn(arrayAllEn);
        setPointsTimelineTitle(arrayPointsTimelineTitle);
        setPointsTimeline(arrayPointsTimeline);
      
      console.log(arrayPointsTimeline)
      },

      onError: (error: any) => {
        alert(error.message);
      },
    }
  );

  return {
    isLoading,
    isFetching,
    error,
    PointsTimelineTitle,
    PointsTimeline,
    allArrays,
    allArraysEn,
    refetch
  };
};

function setArray(title: string, array: any, setArray: any) {
  let Id: number = 0;
  let IdMitos: string = "";
  let Cardtitle: string = "";
  let Thumbnail: string = "";
  let Type: string = "";
  let Longtitude: string = "";
  let Lantitude: string = "";
  let Description: string = "";
  let Images: string = "";
  let arrayImages: any[] = [];
  

  for (var item of Object.keys(array)) {
    switch (item) {
      case "o:id":
        Id = array[item];
        break;
      case "bibo:uri":
        IdMitos = array[item][0]["@value"];
        break;
      case "dcterms:title":
        Cardtitle = i18n.language == 'el' ?array[item][0]["@value"] : array[item][1]["@value"];
        break;
      case "thumbnail_display_urls":
        Thumbnail = array[item]["large"];
        break;
      case "dcterms:subject":
        Type = array[item][0]["@value"];
        break;
      case "dotsoft:longtitude":
        Longtitude = array[item][0]["@value"];
        break;
      case "dotsoft:lantitude":
        Lantitude = array[item][0]["@value"];
        break;
      case "dotportal:mobileDescription":
        Description = i18n.language == 'el' ?array[item][0]["@value"] : array[item][1]["@value"];;
        break;
      case "mobilemedia:lowResImages":
        Images = array[item][0]["@value"];
        break;
    }
  }

  let images = Images.split("\n");
  for (let i in images) {
    arrayImages.push({
      id: "image_" + (parseInt(i) + 1),
      url: images[i],
      alt: Cardtitle + " " + (parseInt(i) + 1),
    });
  }

  setArray.push({
    id: Id,
    idMitos: IdMitos,
    title: title,
    cardTitle: Cardtitle,
    media: {
      name: Cardtitle,
      source: { url: Thumbnail },
      type: "IMAGE",
    },
    cardDetailedText: Description,
    Images: arrayImages,
  });
}
