import React,{useState} from "react"
import { Button, Row, Col, Image, Spin, Divider  } from 'antd'
import classes from "./styles.module.css"
import { usePoint } from '../../../hooks/useMap'
import { useTranslation } from "react-i18next"
import { Link } from 'react-router-dom';
interface Props{
  id: string
}

const PoisItem: React.FC<Props> = ({ id }: Props) => {

  const { t } = useTranslation()

  const { isLoading, isFetching, error, Point } = usePoint(id ? id : '')

  const [showMore, setShowMore] = useState<boolean>(true)

  let textFull =  Point.mobileDescription
  let textShort = `${Point.mobileDescription.substring(0, 2400)}`
  textShort = textShort.slice(0, textShort.lastIndexOf("&"))


  const ViewItem = () => {

  return (
    <div className={classes.box}>
      <img width="100%" src={Point.thumbnail} alt={''} className={classes.img} />
      <span className={classes.title}>{Point.title}</span>
      <div className={classes.descriptionTitle}>{t("Description")}</div>
      <div className={classes.descriptionText} dangerouslySetInnerHTML={{__html: showMore ? textFull  : textShort + ' .... ' }} />
      {/* <Button
          type="primary"
          ghost
          style={{ borderColor: "#808242", borderRadius: '8px', color: '#808242', marginLeft: '10px' }}
          className={classes.btn}
          onClick={() => setShowMore(!showMore)}
        >{showMore ? t("Διαβάστε λιγότερα") : t("Διαβάστε περισσότερα") }
      </Button > */}
      <Link to={`../lindos/${id}`}>
        <Button
          type="primary"
          ghost
          style={{ borderColor: "#3b6e4e", borderRadius: '8px', color: '#3b6e4e', marginLeft: '16px' }}
          className={classes.btn}
        > {t("Δείτε περισσότερα")}
        </Button >
      </Link>
      <Divider />
      <Image.PreviewGroup>
        <Row justify="center">
          {Point.images.map((image: any, index: number) => (
            <Col xs={12} lg={12} xl={12} key={index}>
              <Image className={classes.hover} key={index} src={image.url} alt={image.alt} />
            </Col>
          ))}
        </Row>
      </Image.PreviewGroup>

    </div>
    )
  }
  

  if (isLoading || !Point) {
    return (
      <Row justify="center" >
        <Col span={12} offset={6}>
          <div className={classes.loading}> <Spin size="large" /></div>
        </Col>
      </Row>
    )
  }

  return (
    
    <ViewItem /> 
    
  )
}

export default PoisItem