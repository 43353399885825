import axios from 'axios'

// const API_URL = 'https://chania.mitos.cityguideplatform.com/api'
const API_URL = 'https://lindos-backend.dotsoft.gr'
axios.defaults.baseURL = API_URL
// const X_API_KEY = 'hfG7u8C4UNMw17E6Jyzq'
const X_API_KEY = '1Wt8DjIQ90GuPuovuI1O8M9Axe7OhZA4'


export const axiosPage = {

  async getPage(language: string, page: string) {

    const { data } = await axios.get(`api/${language}/pages/${page}`, {
      headers: {
        'API-KEY': X_API_KEY,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
      }
    })

    return data
  },

  async getQuestBookComments() {

    const { data } = await axios.get(`guest-book/submissions`, {
      headers: {
        'API-KEY': X_API_KEY,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
      }
    })

    return data
  },




  // async postQuestBook(questBookData: QuestBook) {

  //   const { data: response } = await axios.post(`https://lindos-backend.dotsoft.gr/webform_rest/submit`, questBookData)
    
  //   return response.data;
  // }

}


