import { useState } from "react"
import { useQuery } from '@tanstack/react-query'
import { PageData, QuestBookComments } from "../services/props"
import { axiosPage } from "../services/page"

export const usePage = (lang: string, page: string) => {
  
  const [Page, setPage] = useState<PageData>(); 

  const { isLoading, isFetching, isError, error, refetch } = useQuery(
    ['page', page],
    () => axiosPage.getPage(lang, page),
    {
    enabled: page.length > 0,
    onSuccess: (data) => setPage(data),
    onError: (error:any) => alert(error.message)
  })

  return { isLoading, isFetching, isError, error, Page, refetch }
}

export const useQuestBookComments = () => {
  
  const [questBookComments, setQuestBookComments] = useState<QuestBookComments[]>([]); 

  const { isLoading, isFetching, isError, error, refetch } = useQuery(
    ['QuestBookComments'],
    () => axiosPage.getQuestBookComments(),
    {
      onSuccess: (data) => setQuestBookComments(data),
      onError: (error:any) => alert(error.message)
    })

  return { isFetching, questBookComments }
}