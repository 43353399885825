import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { axiosSearch } from "../services/search";

export const useSearch = (lang: string, key: string) => {
  const [Results, setResults] = useState<[]>([]);

  const { isLoading, isFetching, error } = useQuery(["pois", lang, key], () => axiosSearch.getSearch(lang, key), {
      onSuccess: (data) => {
        key == "" ? setResults([]) : setResults(data);
      },

      onError: (error: any) => {
        alert(error.message);
      },
    }
  );

  return { isLoading, isFetching, error, Results };
};