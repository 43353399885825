import axios from 'axios'
import i18n from 'translation'
const API = 'https://lindos-admin.repository.gr/api'

const API2 = 'https://lindos.mitos.cityguideplatform.com/api'
const X_API_KEY = '9jQ5ds4339NhPRw4kkkK'

export const axiosPoints = {


  
  async getPointsForTimeLine() {
    const { data } = await axios.get(`${API}/items`)
    return data
  },

  async getPoints() {
    const { data } = await axios.get(`${API2}/pois?lang=${i18n.language}`, {
      headers: {
        'X-API-KEY': X_API_KEY,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
      }
    })

    console.log(data.success)
    return data.success
  },


  async getPointsHights() {
    const { data } = await axios.get(`${API2}/pois?lang=${i18n.language}&is_highlight=1`, {
      headers: {
        'X-API-KEY': X_API_KEY,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
      }
    })
    return data.success
  },
  

  async getPoint(id: string) {
  
    const { data } = await axios.get(`${API2}/poi?id=${id}&fetch_files=1&lang=${i18n.language}`, {
      headers: {
        'X-API-KEY': X_API_KEY,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
      }
    })
    return data.success
  }

}