import React, {useEffect} from "react"
import classes from './styles.module.css'
import { usePage } from 'hooks/usePage'
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"
import Spinner from 'common/Spinner'
import HelmetComponent from 'components/Helmet'

const CommonPage: React.FC = () => {

  const { i18n } = useTranslation()
  const location = useLocation()

  const { isLoading, isFetching, Page, refetch } = usePage( i18n.language, location.state.page)

   useEffect(() => {
    refetch()
  }, [i18n.language])

  // const { isLoading, isFetching, error, data, refetch } = useQuery( ['page'],
  //   async () => {
  //     const { data } = await axios("https://api.chucknorris.io/jokes/random");
  //     return data;
  //   }
  // );

  // console.log('common page')

  if (isLoading) return <Spinner />
  if (isFetching) return <Spinner text='Ενημέρωση' />
  
  return (
    <>
      <HelmetComponent title='SEO Προσωπικά Δεδομένα και Πολιτική Απορρήτου' descrition="SEO Προσωπικά Δεδομένα και Πολιτική Απορρήτου περιγραφή" />
      
      <div className={classes.container}>
        <h3 className={classes.h3}>{Page?.title }</h3>
        <div dangerouslySetInnerHTML={{ __html: Page?.body || '' }} />
      </div> 
    </>
  )
}

export default CommonPage
