class Utils {


  static MapFilterPointers(Pointers, radioValue) {
	
		let pointers = []

		radioValue === 1
			?
			pointers = Pointers
			:
			pointers = Pointers.filter(point => point.type === radioValue )
		
		return pointers
	}



  static filterEventsArray(list, type, month, current) {

    let data = list
		let eventsArray = []

		if (type === "allEvents" &&
			(month === "allMonths" || month === "Month")
			&& current === "Current") 
			return data
		

		else {

			  data = list.filter(item => {

					let array_type = (type !== "allEvents") ? (item['category_type'] === type) : true
					let array_month = (month !== "allMonths") ? (item['month_code'][0].includes(month)) : true

					return (array_type && array_month )
				})
		}


		data.map(item => {

			Date.prototype.addDays = function (days) {
				const date = new Date(this.valueOf());
				date.setDate(date.getDate() + days);
				return date;
			}
			
			let date = new Date();
			date = date.addDays(parseInt(current))
      const dateSelected = returnDate(date.toLocaleString())


			if (current === '40'){
				eventsArray = data
			} else {

				for (let i = 0; i < item.time_periods.length; i++){
				
					const startDate = returnDate(item.time_periods[i].starting_date)
					const endDate = returnDate(item.time_periods[i].ending_date)
	
					if (item.time_periods[i].starting_date === '12/10/1983, 12:00'){
						eventsArray.push(item)
						continue;
					}
			
					if (dateSelected > startDate && dateSelected < endDate) {
						eventsArray.push(item);
						continue;
					}
				}
			}

		})

		return eventsArray
  }

}


function returnDate(Str) {

	const [StrDay, StrHour ] = Str.split(',')
	const [day, month, year] = StrDay.split('/')
	const date = new Date(+year, month - 1, +day)

	return date
}


export default Utils