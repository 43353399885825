import React, { useState, useEffect, Suspense } from "react"
import { Row, Col, Modal, Typography, Tooltip  } from 'antd'
import classes from './styles.module.css'
import { useTranslation } from "react-i18next"
import HelmetComponent from 'components/Helmet'
import { SVG_Acropoli } from '../../images/SVG_Acropoli_Oikismos_Ypaifros'
import { ThreeD } from "./ThreeD"
import { ThreeDMain } from "./ThreeDMain"
import ModelViewer from "../Acropoli/components/modelviewer";
import SpinSmall from 'common/Spin'

const { Title } = Typography;

const Applications: React.FC = () => {

  const { t } = useTranslation();


  const ModalComponent = (props: any) => {

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const showModal = () => {
      setIsModalOpen(true);
    };

    const handleCancel = () => {
      setIsModalOpen(false);
      window.location.reload();
    };

    return (
      <> 
        <Modal title={<Title level={4}>{t(props.title)}</Title>}
          open={isModalOpen}
          footer={null}
          centered
          width='100%'
          bodyStyle={{width: '100%', height: '800px'}}
          onCancel={handleCancel}
        >
         <Suspense fallback={ <SpinSmall />} >
            <div style={{ height: '770px' }}>   
              <ModelViewer modelPath={props.gld} />
            </div>
          </Suspense>
        </Modal >

        {/* <Tooltip placement="top" title={props.title}> */}
          <div className={classes.div3d} onClick={showModal}>
            <img src={props.image} alt="acropoli" className={classes.div3dImage} />
            <span className={classes.spanTitle}>{t(props.title)}</span>
          </div>
        {/* </Tooltip> */}
      </>
    )
  }
  
  return (
    <>
      <HelmetComponent title='Applications' descrition="Applications" />
      
      <Row>
        <div className={classes.top_banner}>
          <Col xs={24} sm={12} lg={12}>
            <img src="../threeD/applications.png" alt="Applications" />
          </Col>
          <Col xs={24} sm={12} lg={12}>
            <div className={classes.top_banner_txt}>
              <p className={classes.top_banner_txt1}>{t("Κατηγορία Σημείου")}</p>
              <p className={classes.top_banner_txt2}> { <SVG_Acropoli /> }&nbsp; &nbsp;{t('Ακρόπολη')}</p>
              <h2>{t('Ακρόπολη της Λίνδου')}</h2>
            </div>
          </Col>
        </div>
      </Row>

      <div className={classes.container}>
        <h3 className={classes.h3}>{t('Τρισδιάστατες αναπαραστάσεις της σημερινής εποχής')}</h3>
      

        <Row>
        <Suspense fallback={ <SpinSmall /> }>
          {ThreeD.map((item: any) => {
            return (
              <Col xs={24} sm={12} lg={6} key={item.id}>
                <ModalComponent {...item}  />
              </Col>
            )
          })
          }</Suspense>
        </Row>

        
        <h3 className={classes.h32}>{t('Εικονικές αναπαραστάσεις')}</h3>
        <Row>
        <Suspense fallback={ <SpinSmall /> }>
          {ThreeDMain.map((item: any) => {
            return (
              <Col xs={24} sm={12} lg={6} key={item.id}>
                <ModalComponent {...item}  />
              </Col>
            )
          })
          }</Suspense>
        </Row>

      </div> 
    </>
  )
}

export default Applications
