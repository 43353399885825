import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
// import XHR from "i18next-xhr-backend";

import translationEn from "./locales/en/translation.json";
import translationDe from "./locales/de/translation.json";
import translationGr from "./locales/gr/translation.json";
import translationRu from "./locales/ru/translation.json";

i18n
  // .use(XHR)
  .use(LanguageDetector)
  .init({
    debug: false,
    lng: "el",
    fallbackLng: "el",
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },

    resources: {
      en: {
        translations: translationEn,
      },
      de: {
        translations: translationDe,
      },
      el: {
        translations: translationGr,
      },
      ru: {
        translations: translationRu,
      },
    },
    detection: {
      order: ["path", "cookie"],
      caches: ["cookie"],
      cookieMinutes: 7 * 24 * 60 * 60 * 1000, //7 days
    },
    react: { useSuspense: false },
    ns: ["translations"],
    defaultNS: "translations",
  });

export default i18n;