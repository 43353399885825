
import React from "react"
import Props from './svgProps'

const RouinsSVG = ({width, height, fill}:Props) => {

    return (
    <svg
        width={width}
        height={height}
        viewBox="0 0 64 64"
				fill={fill} xmlns="http://www.w3.org/2000/svg">

				<path d="M63,58h-3v-3c0-0.553-0.448-1-1-1h-3V29c0-0.379-0.214-0.725-0.553-0.895l-3.66-1.83l-1.858-4.646
						C49.777,21.249,49.409,21,49,21h-2.001c-0.379,0-0.725,0.214-0.894,0.553l-2,4C44.036,25.691,44,25.845,44,26v28h-6V22
						c0-0.315-0.148-0.611-0.4-0.8l-4-3C33.427,18.07,33.217,18,33,18h-1.465l-1.704-2.555C29.646,15.167,29.334,15,28.999,15H27
						c-0.552,0-1,0.447-1,1v38h-6V8.976C20.844,9.617,21.884,10,23,10c2.757,0,5-2.243,5-5s-2.243-5-5-5H5C2.243,0,0,2.243,0,5
						s2.243,5,5,5c1.116,0,2.156-0.383,3-1.024V54H5c-0.552,0-1,0.447-1,1v3H1c-0.552,0-1,0.447-1,1v4c0,0.553,0.448,1,1,1h62
						c0.552,0,1-0.447,1-1v-4C64,58.447,63.552,58,63,58z M46,35.414l2,2V42c0,0.266,0.105,0.52,0.293,0.707l2,2l1.414-1.414L50,41.586
						v-4.172l2.707-2.707l-1.414-1.414L49,35.586l-3-3v-6.35L47.618,23h0.705l1.749,4.371c0.091,0.228,0.262,0.414,0.481,0.523
						L54,29.618v22.968l-2-2V48h-2v2h-2v2h2.586l2,2H46V35.414z M28,47.414l2-2l1,1V50c0,0.266,0.105,0.52,0.293,0.707l1.5,1.5
						l1.414-1.414L33,49.586V46c0-0.266-0.106-0.52-0.293-0.707l-2-2c-0.391-0.391-1.023-0.391-1.414,0L28,44.586V28.63l2,2.25V36
						c0,0.266,0.105,0.52,0.293,0.707l2,2l1.414-1.414L32,35.586V30.5c0-0.025-0.012-0.048-0.013-0.073l1.72-1.72
						C33.894,28.52,34,28.266,34,28v-4h-2v3.586l-1.192,1.193L28,25.62V17h0.464l1.704,2.555C30.354,19.833,30.666,20,31,20h1.667
						L36,22.5v20.086l-2.293-2.293l-1.414,1.414L36,45.414V54h-8V47.414z M10,7h8v2h-8V7z M5,8C3.346,8,2,6.654,2,5s1.346-3,3-3h18
						c1.654,0,3,1.346,3,3s-1.346,3-3,3c-1.359,0-2.547-0.926-2.89-2.251C19.996,5.309,19.597,5,19.142,5H19H9H8.858
						C8.403,5,8.004,5.309,7.89,5.749C7.547,7.074,6.359,8,5,8z M10,52.414l2.207-2.207l-1.414-1.414L10,49.586V29.914l1.793,1.793
						C11.98,31.895,12.235,32,12.5,32H15v-2h-2.086L10,27.086v-3.672l2.707-2.707l-1.414-1.414L10,20.586V11h8v12.796l-2-1.75V17
						c0-0.266-0.106-0.52-0.293-0.707l-2.5-2.5l-1.414,1.414L14,17.414V22.5c0,0.288,0.124,0.562,0.341,0.753L18,26.454v22.132l-4-4
						v-5.172l2.207-2.207l-1.414-1.414L14,36.586l-1.293-1.293l-1.414,1.414L12.586,38l-0.293,0.293C12.105,38.48,12,38.734,12,39v6
						c0,0.266,0.105,0.52,0.293,0.707L18,51.414V54h-8V52.414z M6,56h3h10h8h10h8h10h3v2H6V56z M62,62H2v-2h3h54h3V62z"/>

    </svg>
    )
}

export default RouinsSVG