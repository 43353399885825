import axios from 'axios'
import { IEvents } from "../services/props"

const API = 'https://lindos-admin.repository.gr/api'

export const axiosEvents = {

  async getEvents() {
    // const { data } = await axios.get(`https://events.explorechania.gr/api/el/events`)
    const { data } = await axios.get(`https://lindos-backend.dotsoft.gr/api/${window.localStorage.getItem("lng")}/events`)
    return data.events


  }

}