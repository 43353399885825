import React, { useState, useEffect } from "react"
import classes from "./Search.module.css"
import { useTranslation } from "react-i18next"
import SvgSmallClose from "../Search/SvgSmallClose"
import { useSearch } from '../../hooks/useSearch'
import Spinner from '../../common/Spinner'
import { Link } from "react-router-dom";
interface Props {
  onClick?(): void;
}

const Search: React.FC<Props> = ({ onClick }) => {

  const { t, i18n } = useTranslation();

  const [word, setWord] = useState<string>('')

  const { isLoading, error, Results } = useSearch(i18n.language === 'gr' ? 'el' : i18n.language, word)

  const [totalNumberOfResults, setTotalNumberOfResults] = useState<number>(1);

  useEffect(() => {
    setTotalNumberOfResults(Object?.keys(Results)?.length!)
  }, [Results, word])



  const Timer = (e: any) => {

    setTimeout(() => {
      setWord(e)
    }, 300)
  }

 

  return (
    <>
      <div className={`${classes.search}`}>
        <SvgSmallClose
          className={classes.closeButton}
          width={70}
          height={70}
          strokeWidth={2}
          onClick={() => onClick && onClick()}
        />

        <div className={`${classes.searchWrapper}`}>
          <div className={`${classes.searchContent}`}>
            <h1>{t("Αναζήτηση")}</h1>
            <input
              type={"search"}
              placeholder={t("Πληκτρολογήστε τον όρο αναζήτησης")}
              onChange={e => Timer(e.currentTarget.value)}
            />
          </div>

          <h1 className={classes.result_message}>
            {totalNumberOfResults > 0
              ? t("Βρέθηκαν") + " " + totalNumberOfResults + " " + t("αποτελέσματα")
              : (word == '') ? ''
                : (word != '') && t("Δεν βρέθηκαν αποτελέσματα αναζήτησης")
            }
          </h1>

          <div className={classes.results_list}>
            {Results &&
              Results.map((item: { [key: string]: any }, index) => (
                <span key={index}>
                  <Link onClick={() => onClick && onClick()} to={item.address ? `../lindos/${item.id}` : `../timeline`}>
                    <div className={classes.result_item} key={item.id}>
                      {/* <h2>{item?.name}</h2> */}
                      <h2>{item.category_path[0]}</h2>

                      <section>

                        <h1>{item?.name}</h1>

                        {/* <div dangerouslySetInnerHTML={{ __html: item?.main_info }} /> */}

                      </section>

                    </div>
                  </Link>
                </span>
              )
              )
            }
          </div>



        </div>
      </div>
    </>
  )
}

export default Search
