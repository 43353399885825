import React, { useState, useEffect } from "react"
import { Row, Col, Select } from 'antd'
import { useTranslation } from "react-i18next"
import { useEventsMap } from 'hooks/useEvents'
import Card from './CardComponent'
import { GeneralProps } from "services/props"
import utils from 'utils'
import classes from "./styles.module.css"
import { useLocation } from "react-router-dom"
import Spinner from 'common/Spinner'
import HelmetComponent from 'components/Helmet'


const { Option } = Select

const Events = () => {

  const { t } = useTranslation()

  const { isLoading, error, Events } = useEventsMap()

  const location = useLocation()
  const [count, setCount] = useState<number>(1);
  const [listData, setListData] = useState<GeneralProps[]>(Events);

  const [type, setType] = useState<string>('allEvents');
  const [month, setMonth] = useState<string>('allMonths');
  const [current, setCurrent] = useState<string>(location?.state?.day ? location?.state?.day : '40');


  useEffect(() => {
    if (current != '40') {
      let data = utils.filterEventsArray(Events, type, month, current)
      setListData(data)
      setCount(data.length)
    }
    else {
      setCount(Events.length)
      setListData(Events)
    }
  }, [Events])



  const handleChange = (key: string, value: string): void => { 
    
    switch (key) {
      case 'type':
        let datas = utils.filterEventsArray(Events, type, month, current)
        setListData(datas)
        setType(value)
        break;
      
      case 'month':
        setMonth(value);
        break;
      
      default: 
        setCurrent(value)
    }
  };

  const handleChangeTime = (value:string):void => setCurrent(value)

  useEffect(() => {
   
    let data = utils.filterEventsArray(Events, type, month, current)
    setCount(data.length)
    if (type === "allEvents" && month === "allMonths" && current === "40") {
      setListData(Events)
    }
    else {
      setListData(data)
    }
  }, [type, month, current])
  


  // if (isLoading || listData.length < 1) { return <Spinner /> }
  if (isLoading || listData.length < 1) {
    return (

    <Row justify="center">
      <Col span={12}> <h1 className={classes.calendarContainer__container} >{t("Ημερολόγιο Εκδηλώσεων")} (No Data)</h1></Col>
    </Row>

    )
}





  return (
    <>
      <HelmetComponent title={t('SEO Events title')} descrition={t('SEO Events description')} />
      
      <Row justify="center">
        <Col span={12}> <h1 className={classes.calendarContainer__container} >{t("Ημερολόγιο Εκδηλώσεων")} (The data from Chania)</h1></Col>
      </Row>

      <Row justify="center" >
            
        <Col xs={24} xl={8} lg={8} span={4}>
          <h5>{count} {count && count < 2 ? `${t("Εκδήλωση")}` : `${t("Εκδηλώσεις")}`}  </h5>

          <Select
            placeholder={t("EventsAll")}
            className={classes.type}
            size={'large'}
            placement="bottomLeft"
            showArrow={false}
            defaultValue={'allEvents'}
            autoClearSearchValue={false}
            onChange={(e) => handleChange("type", e)} >
            
            <Option value="allEvents" className={'ant-option'}>{ t("EventsAll") }</Option> 
            <Option value="Κινηματογράφος" className={'ant-option'}>{t('Κινηματογράφος')}</Option>
            <Option value="Εργαστήριο" className={'ant-option'}>{t('Εργαστήριο')}</Option>
            <Option value="Εκδηλώσεις" className={'ant-option'}>{t('Εκδηλώσεις')}</Option>
            <Option value="Συναυλίες" className={'ant-option'}>{t('Συναυλίες')}</Option>
            <Option value="Χορός" className={'ant-option'}>{t('Χορός')}</Option>
            <Option value="Έκθεση" className={'ant-option'}>{t('Έκθεση')}</Option>
            <Option value="Θέατρο" className={'ant-option'}>{t('Θέατρο')}</Option>
            <Option value="Ρεσιτάλ" className={'ant-option'}>{t('Ρεσιτάλ')}</Option>
            <Option value="Συνέδριο" className={'ant-option'}>{t('Συνέδριο')}</Option>
          </Select>

          <Select
            placeholder={t("allMonths")}
            className={classes.month}
            size={'large'}
            defaultValue={'allMonths'}
            showArrow={false}
            autoClearSearchValue={false}
            onChange={(e) => handleChange("month", e)} >
   
            <Option value="allMonths" className={'ant-option'}>{t("allMonths")}</Option>
            <Option value="Jan" className={'ant-option'}>{t("MonthJanuary")}</Option>
            <Option value="Feb" className={'ant-option'}>{t("MonthFebruary")}</Option>
            <Option value="Mar" className={'ant-option'}>{t("MonthMarch")}</Option>
            <Option value="Apr" className={'ant-option'}>{t("MonthApril")}</Option>
            <Option value="May" className={'ant-option'}>{t("MonthMay")}</Option>
            <Option value="Jun" className={'ant-option'}>{t("MonthJune")}</Option>
            <Option value="Jul" className={'ant-option'}>{t("MonthJuly")}</Option>
            <Option value="Aug" className={'ant-option'}>{t("MonthAugust")}</Option>
            <Option value="Sep" className={'ant-option'}>{t("MonthSeptember")}</Option>
            <Option value="Oct" className={'ant-option'}>{t("MonthOctober")}</Option>
            <Option value="Nov" className={'ant-option'}>{t("MonthNovember")}</Option>
            <Option value="Dec" className={'ant-option'}>{t("MonthDecember")}</Option>
          </Select>

          {/* <Select
            placeholder={t("Current")}
            style={{ width: '30%', padding: '4px', color: '#009664' }}
            className={'calendar'}
            size={'large'}
            allowClear={true}
            showArrow={false}
            autoClearSearchValue={false}
            onChange={(e) => handleChange("current", e)} >
            <Option value="cur">{t("Current")}</Option>
            <Option value="att">{t("Attention")}</Option>
            <Option value="old">{t("OlderOnes")}</Option>
          </Select> */}

          <Select
            placeholder={t("Όλες οι μέρες")}
            className={classes.days}
            size={'large'}
            showArrow={false}
            defaultValue={location?.state?.day ? location?.state?.day : '40'}
            onChange={handleChangeTime} >
            
            <Option value="40">{t('Όλες οι μέρες')}</Option>
            <Option value="1">{t('Today')}</Option>
            <Option value="2">{ t('Αύριο')}</Option>
            <Option value="7">{ t('In a week')}</Option>
            <Option value="30">{t('In a month')}</Option>
          </Select>

        </Col>
      </Row>
      <br />


      <Row justify="center" gutter={[16, 16]}>

        {listData && listData.map((event: { [index: string]: any }, index: number) => {

          return (

            <Col xs={{ span: 24 }} lg={{ span: 4 }} key={index}>

              <Card
                styleImage={true}
                type={t(event.category_type)}
                image={event.image}
                event={event.title}
                description={event.description}
                municipality={event.municipality}
                start={event.time_periods[0].starting_date}
                end={event.time_periods[0].ending_date}
                t={
                  event.price.length > 0
                    ?
                    event.price.map((prices: any) => prices.price != 0 ? `${prices.description + ' ' + prices.price} € \n` : t("Είσοδος ελεύθερη"))
                    :
                    t("Είσοδος ελεύθερη")
                }
              />
            </Col>
          )}
        )}
      </Row>
    </>
  )
}

export default Events