import React, { useState } from "react"
import type { MenuProps } from 'antd'
import { Row, Col, Drawer, Dropdown, Space, Menu } from 'antd'
import { PushpinOutlined, GlobalOutlined, SearchOutlined } from '@ant-design/icons'
import 'antd/dist/antd.min.css'
import { HashLink } from 'react-router-hash-link'

import Container from "../../common/Container"
import { Icon } from "../../common/Icon"
import { Link } from "react-router-dom"
import SearchClickComponent from "../Search/SearchClickComponent"


import { withTranslation } from "react-i18next"
import i18n from "i18next"
import {
  HeaderSection,
  LogoContainer,
  Burger,
  NotHidden,
  Menus,
  CustomNavLinkSmall,
  CustomNavLinkSmallMap,
  CustomNavLinkSmallSearch,
  CustomNavLinkSmallLanguage,
  Label,
  Outline,
  Span,
  GeneralCover,
} from "./styles"

const Header = ({ t }: any) => {

  const [visible, setVisibility] = useState(false)

  const onClick: MenuProps['onClick'] = ({ key }: any) => {
    i18n.changeLanguage(key)
    window.localStorage.setItem("lng", key);
    
    // window.location.reload();
  };

  const menu = (
    <Menu
      onClick={onClick}
      items={[
        {
          key: 'en',
          label: (
            <>
              English
            </>
          )
        },
        {
          key: 'el',
          label: (
            <>
              Ελληνικά
            </>
          ),
        }
      ]}
    />
  )

  const showDrawer = () => {
    setVisibility(!visible)
  };

  const onClose = () => {
    setVisibility(!visible)
  };

  const MenuItem: React.FC = () => {

    return (
      <>

        <GeneralCover>

          {/* <CustomNavLinkSmall>
          <Span>
            <nav>
              <Link to="/acropolis_of_Lindos" >{t("Acropolis of Lindos")}</Link>
            </nav>
          </Span>
        </CustomNavLinkSmall> */}
          <CustomNavLinkSmall>
            <Span>
              <nav>
                <Link to="/applications">{t("Applications")}</Link>
              </nav>
            </Span>
          </CustomNavLinkSmall>

          <CustomNavLinkSmall>
            <Span>
              <nav>
                <Link to="/timeline">{t("Timeline")}</Link>
              </nav>
            </Span>
          </CustomNavLinkSmall>
          <CustomNavLinkSmall>
            <Span>
              <nav>
                <HashLink to="/#MobileSection">Mobile App</HashLink>
              </nav>
            </Span>
          </CustomNavLinkSmall>

          <CustomNavLinkSmall>
            <Span>
              <nav>
                <Link to="/events">{t("Ημερολόγιο")}</Link>
              </nav>
            </Span>
          </CustomNavLinkSmall>



          <CustomNavLinkSmall>
            <Span>
              <nav>
                <Link to="/map">{t("Χάρτης")}</Link>
              </nav>
            </Span>
          </CustomNavLinkSmall>

          {/* <CustomNavLinkSmallMap>
          <Link to="/map" ><span style={{display: 'none'}}>Map</span>
              <Space> <PushpinOutlined /> </Space>
          </Link>
        </CustomNavLinkSmallMap> */}

          <CustomNavLinkSmallSearch>
            <SearchClickComponent item={<Space> <SearchOutlined /> </Space>} />
          </CustomNavLinkSmallSearch>

          <CustomNavLinkSmallLanguage>
            <Dropdown overlay={menu} >
              <a href="#!" onClick={(e) => e.preventDefault()}><span style={{ display: 'none' }}>Map</span>
                <Space> <GlobalOutlined /> </Space>
              </a>
            </Dropdown>
          </CustomNavLinkSmallLanguage>


        </GeneralCover>
      </>
    )
  }


  return (
    <HeaderSection>
      <Container>
        <Row justify="space-between" id="home">
          <LogoContainer to="/" aria-label="homepage" >
            <Icon src='logo.png' width="160px" height="66px" />
          </LogoContainer>
          <NotHidden>
            <MenuItem />
          </NotHidden>
          <Burger onClick={showDrawer}>
            <Outline />
          </Burger>
        </Row>
        <Drawer closable={false} open={visible} onClose={onClose}>
          <Col style={{ marginRight: "1.6rem" }}>
            <Label onClick={onClose}>
              <Col span={12}>
                <Menus>{t("Μενού")}</Menus>
              </Col>
              <Col span={10}>
                <Outline />
              </Col>
            </Label>
          </Col>
          <MenuItem />
        </Drawer>
      </Container>
    </HeaderSection>
  )
}

export default withTranslation()(Header)