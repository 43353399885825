import React, { Suspense } from "react";
import { Canvas } from "@react-three/fiber";
import GltfModel from "./gltf-model";
import { OrbitControls} from '@react-three/drei'

export default function ModelViewer({ modelPath }) {

console.log(modelPath)
  // OrbitControls.maxDistance = 1
  // let canvas = document.getElementsByTagName("canvas");
  // const ctx = canvas.getContext ("2d");
  // document.querySelector('canvas').setAttribute("width", '2200px');
  // console.log(canvas)

    // let ctx = canvas.getContext("3d");
  //     ctx.fillStyle = "green";
  //     ctx.fillRect(0, 0, canvas.width, canvas.height);
  //     ctx.beginPath();
  //     ctx.lineWidth = 10;
  //     ctx.arc(canvas.width / 2, canvas.height / 2, 50, 0, 2 * Math.PI);
  // ctx.stroke();
  
  return (

<>
    <Canvas>
      <ambientLight intensity={0.4} />
      <spotLight position={[120, 150, 100]} angle={0.3} penumbra={1} />
      <pointLight position={[110, 20, 0]} />
      {/* <Suspense fallback={null}> */}
        <GltfModel modelPath={modelPath} />
      {/* </Suspense> */}
        <OrbitControls
          minDistance={
            ( modelPath == 'threeD/naos_afinas_main.glb' ||
              modelPath == 'threeD/stoa_main.glb' ||
              modelPath == 'threeD/ippotiko.glb'||

              modelPath == '../threeD/naos_afinas_main.glb' ||
              modelPath == '../threeD/stoa_main.glb' ||
              modelPath == '../threeD/naos_afinas.glb' ||
              modelPath == '../threeD/stoa.glb' ||
              modelPath == '../threeD/ippotiko.glb'
            )
              ? 88 : 0}
          target={modelPath == 'threeD/ippotiko_main.glb' ? [0, 60, 0] : [0, 1, 0]}
        />
    </Canvas>
</>
  );
}