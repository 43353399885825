import React from "react"
import { Row, Col, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'


const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

const SpinSmall: React.FC = () => {


  return (
    <Row justify="center" >
      <Col span={2}>
        <div style={{margin: '40px'}}>
          <Spin indicator={antIcon} />
        </div>
      </Col>
    </Row>
  )
}

export default SpinSmall