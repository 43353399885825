import React,{Suspense, useState, useEffect} from "react"
import { Row, Col } from 'antd'
import classes from './styles.module.css'
import { usePoint } from 'hooks/useMap'
import { useTranslation } from "react-i18next"
import { useParams } from 'react-router-dom'
import ComponentTabs from './components/Tab'
import Spinner from 'common/Spinner'
import SpinSmall from 'common/Spin'
import { SVG_Acropoli, SVG_Oikismos, SVG_Ypaifro } from '../../images/SVG_Acropoli_Oikismos_Ypaifros'
import HelmetComponent from 'components/Helmet'
import i18n from 'translation'

const check3DArray = ['1_60', '1_52', '1_48', '1_63', '1_101', '1_102', '1_103', '1_105', '1_107', '1_108', '1_109']

const Acropoli: React.FC = () => {

  const { t } = useTranslation()
  const { id } = useParams()

  const [check, setCheck] = useState<boolean>(false);
  const [threeD, setThreeD] = useState<string>('');
  const [threeDMain, setThreeDMain] = useState<string>('');
  const { isLoading, isFetching, Point, refetch } = usePoint( id ? id : '' )
   
  useEffect(() => {
    refetch()
  }, [i18n.language])

  useEffect(() => {
   
    setCheck(check3DArray.includes( Point.id )) 

    switch (Point.id) {
      case '1_60': setThreeD("../threeD/acropoli.glb"); setThreeDMain(""); break;  
      case '1_52': setThreeD("../threeD/naos_afinas.glb");  setThreeDMain("../threeD/naos_afinas_main.glb");break;  
      case '1_48': setThreeD("../threeD/stoa.glb");  setThreeDMain("../threeD/stoa_main.glb");break;  
      case '1_63': setThreeD("../threeD/ippotiko.glb"); setThreeDMain(""); break;
     
      case '1_108': setThreeD("../threeD/naos_agiou_georgiou.glb"); setThreeDMain(""); break;
      case '1_109': setThreeD("../threeD/naos_panagias_agias_varvaras.glb"); setThreeDMain(""); break;
      case '1_107': setThreeD("../threeD/church_menas.glb"); setThreeDMain(""); break;
      case '1_105': setThreeD("../threeD/church_agios_georgios_pachym.glb"); setThreeDMain(""); break;
      case '1_102': setThreeD("../threeD/church_michail_stratilatis.glb"); setThreeDMain(""); break;
      case '1_103': setThreeD("../threeD/church_agios_dimitrios.glb"); setThreeDMain(""); break;
      case '1_101': setThreeD("../threeD/church_metamorfosis_sotiros.glb"); setThreeDMain(""); break;

      default: setThreeD("");
    }

  }, [Point]);



  if (isLoading) return <Spinner text='Παρακαλώ περιμένετε' />

  return (
    <>
      <HelmetComponent title={Point.title} descrition={ `${Point.title} ${t("στην")} ${Point.subject}` } image={Point.thumbnail} />

      <Row>
        <div className={classes.top_banner}>
          <Col xs={24} sm={12} lg={12}>
            <img src={Point.thumbnail} alt={Point.type} className={classes.bannerImage} />
          </Col>
          <Col xs={24} sm={12} lg={12}>
            <div className={classes.top_banner_txt}>
              <p className={classes.top_banner_txt1}>{t("Κατηγορία Σημείου")}</p>
              <p className={classes.top_banner_txt2}> { t(Point.subject) == 'Ακρόπολη' ? <SVG_Acropoli /> : t(Point.subject) == 'Ύπαιθρος' ? <SVG_Ypaifro /> : <SVG_Oikismos />}&nbsp; &nbsp;{t(Point.subject)}</p>
              <h2>{t(Point.title)}</h2>
            </div>
          </Col>
        </div>
      </Row>
     
      <Row justify="center">
        <Col xs={24} sm={20} md={20} lg={20} >
          <Suspense fallback={ <SpinSmall />}>
            <ComponentTabs
              Point={Point}
              check={check}
              threeD={threeD}
              threeDMain={threeDMain}
            />
          </Suspense>
        </Col>
      </Row>
    </>
  )
}

export default Acropoli