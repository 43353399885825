export const ThreeDMain = [
  // {
  //   id: 1,
  //   title: 'Λίνδος - Ακρόπολη',
  //   image: 'img/3d/acropoli.png',
  //   link: 'lindos/1_60',
  //   gld: 'threeD/acropoli.glb',
  //   css: 's1'
  // },
  {
    id: 2,
    title: 'Ναός Αθηνάς',
    image: 'img/3d/naos_afinas_main.png',
    link: 'lindos/1_52',
    gld: 'threeD/naos_afinas_main.glb',
    css: 's2'
  },
  {
    id: 3,
    title: 'Ελληνιστική Στοά',
    image: 'img/3d/stoa_main.png',
    link: 'lindos/1_48',
    gld: 'threeD/stoa_main.glb',
    css: 's3'
  },
  // {
  //   id: 4,
  //   title: 'Το ιπποτικό Διοικητήριο',
  //   image: 'img/3d/ippotiko_main.png',
  //   link: 'lindos/1_63',
  //   gld: 'threeD/ippotiko_main.glb',
  //   css: 's4'
  // },
]
