import React from 'react'
import ReactDOM from 'react-dom/client'
import { Styles } from "./styles/styles"
import { BrowserRouter, Routes, Route, Navigate} from "react-router-dom"
import { I18nextProvider } from "react-i18next"
import ScrollToTop from '../src/common/ScrollToTop'
import i18n from "./translation";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import Header from "./components/Header"
import Footer from "./components/Footer"

import Home from './pages/Home/Home'
import Acropoli from './pages/Acropoli/Acropoli'
import Applications from './pages/Applications/Applications'
import Timelines from './pages/Timeline/Timeline'
import TimelinesEn from './pages/TimelineEn/Timeline'


import Map from './pages/Map/Map'
import Events from './pages/Events/Events'
import CommonPage from './pages/CommonPage/CommonPage'
import PageNotFound from './pages/404'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    }
  }
})

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <I18nextProvider i18n={i18n}>
        <Styles />
        <ScrollToTop />
        <Header />
        <Routes>

          <Route path="/" element={<Home />} />
          <Route index element={<Home />} />
          <Route path="lindos/:id" element={<Acropoli />} />
          <Route path="applications" element={<Applications />} />
          <Route path="timeline" element={<Timelines />} />
          <Route path="timelineEn" element={<TimelinesEn />} />
          <Route path="map" element={<Map />} />
          <Route path="events" element={<Events />} />
          <Route path="page/:page" element={<CommonPage />} />
          <Route path="*" element={<PageNotFound />} />
          {/* <Route path="*" element={<Navigate to="/" />} /> */}
        </Routes>
        <Footer />
      </I18nextProvider>
    </BrowserRouter>
  </QueryClientProvider>
)
