import { createGlobalStyle } from "styled-components";

export const Styles = createGlobalStyle`

h2 { font-family: 'Source Serif 4', serif;}

  .ant-tabs {
    &-nav {
      display: flex;
  
      .ant-tabs-tab {
        flex-grow: 1;
        margin-right: 0px;
        width: 98%;
        text-align: center;
      }
    }
  }

  .ant-tabs>.ant-tabs-nav .ant-tabs-nav-list, .ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-list {
    position: relative;
    display: flex;
    transition: transform .3s;
    width: 98%;
  }

  .ant-tabs-tab .ant-tabs-tab-btn {
    text-shadow: 0 0 .25px currentcolor;
    margin: 0 auto;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    text-shadow: 0 0 .25px currentcolor;
    margin: 0 auto;
  }

  .ant-tabs-tab-active svg, .ant-tabs-tab:hover svg  {
   color: #B9BC66 !important;
  }

  .ant-tabs-tab-active span, .ant-tabs-tab:hover span   {
    color: #B9BC66 !important;
   }


//////   for bottom border   ///////////
  .ant-tabs-ink-bar {
    height: 5px;
    background: #B9BC66;
  }



  /* Custom Coloring */

  .ant-carousel .slick-dots li button {
      opacity: 0.3;
      width:20px;
      height:20px;
      border-radius:50%;
      border:2px solid white;
      background-color: transparent;
  }
  .ant-carousel .slick-dots li.slick-active button {   
      opacity: 1;
      width:30px;
      height:30px;
      border-radius:50%;
      border:2px solid white;
      background-color: transparent;
      margin-top:-4px;
  }
  .ant-carousel .slick-dots li {
      margin-right: 16px;
  } 

  .ant-carousel .slick-dots-bottom {
    bottom: 140px;
  }

  .ant-carousel {
    .slick-next {
        &::before {
        content: '';
        }
    }
    .slick-prev { 
        &::before {
        content: '';
        }
    }
  }



  .ant-select:not(.ant-select-customize-input) .ant-select-selector{
    border-radius: 20px;}

  .ant-drawer-body {
    padding: 4px;
   
    
  }

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  ::-webkit-scrollbar-button {
      background: #green
  }
  ::-webkit-scrollbar-track-piece {
      background: #888
  }
  ::-webkit-scrollbar-thumb {
      background: #eee
  }​



  

  .ant-col-24.ant-form-item-label, .ant-col-xl-24.ant-form-item-label, .ant-form-vertical .ant-form-item-label {
    display: none;
  }

  @media only screen and (max-width: 600px) { 
      .ant-carousel .slick-prev {
        left:-56px;
        top: 200px;
        
        
      }
      .ant-carousel .slick-next {
        right:-40px;
        top: 200px;
      }
  }


  body{ font-family: 'Source Serif 4', serif;}

`;