export const ThreeD = [

  {
    id: 2,
    title: 'Ναός Αθηνάς',
    image: 'img/3d/naos_afinas.png',
    link: 'lindos/1_52',
    gld: 'threeD/naos_afinas.glb',
    css: 's2'
  },
  {
    id: 3,
    title: 'Ελληνιστική Στοά',
    image: 'img/3d/stoa.png',
    link: 'lindos/1_48',
    gld: 'threeD/stoa.glb',
    css: 's3'
  },
  {
    id: 4,
    title: 'Iπποτικό Διοικητήριο',
    image: 'img/3d/ippotiko.png',
    link: 'lindos/1_63',
    gld: 'threeD/ippotiko.glb',
    css: 's4'
  },
  {
    id: 1,
    title: 'Ακρόπολη',
    image: 'img/3d/acropoli.png',
    link: 'lindos/1_60',
    gld: 'threeD/acropoli.glb',
    css: 's1'
  },
  {
    id: 10,
    title: 'Ο ναός του Αγίου Γεωργίου του Χωστού - Άνω',
    image: 'img/3d/naos_agiou_georgiou.png',
    link: 'lindos/1_108',
    gld: 'threeD/naos_agiou_georgiou.glb',
    css: 's1'
  },
  {
    id: 11,
    title: 'Ο ναός της Παναγίας - Αγίας Βαρβάρας Ευλώ',
    image: 'img/3d/naos_panagias_agias_varvaras.png',
    link: 'lindos/1_109',
    gld: 'threeD/naos_panagias_agias_varvaras.glb',
    css: 's1'
  },






  {
    id: 12,
    title: 'Ο ναός του Αγίου Μηνά',
    image: 'img/3d/church_menas.png',
    link: 'lindos/1_107',
    gld: 'threeD/church_menas.glb',
    css: 's5'
  },
  {
    id: 13,
    title: 'Ο ναός του Αγίου Γεωργίου του Παχυμαχιώτη',
    image: 'img/3d/church_agios_georgios_pachym.png',
    link: 'lindos/1_105',
    gld: 'threeD/church_agios_georgios_pachym.glb',
    css: 's1'
  },
  {
    id: 14,
    title: 'Ο ναός του Ταξιάρχη Μιχαήλ Στρατηλάτη',
    image: 'img/3d/church_michail_stratilatis.png',
    link: 'lindos/1_102',
    gld: 'threeD/church_michail_stratilatis.glb',
    css: 's2'
  },
  {
    id: 15,
    title: 'Ο ναός του Αγίου Δημητρίου',
    image: 'img/3d/church_agios_dimitrios.png',
    link: 'lindos/1_103',
    gld: 'threeD/church_agios_dimitrios.glb',
    css: 's3'
  },
  {
    id: 16,
    title: 'Ο ναός της Μεταμόρφωσης του Σωτήρος',
    image: 'img/3d/church_metamorfosis_sotiros.png',
    link: 'lindos/1_101',
    gld: 'threeD/church_metamorfosis_sotiros.glb',
    css: 's4'
  },







]
