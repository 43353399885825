import React, { useState, useEffect } from "react"
import { Button, Modal, Image, Row, Col, Typography  } from 'antd'
import { Chrono } from "react-chrono"
import classes from './styles.module.css'
import { usePointsTimeline } from '../../hooks/useMap'
import { TimelineTitle } from "../../services/props"
import { useTranslation } from "react-i18next"
import Spinner from '../../common/Spinner'
import { Link, useNavigate  } from 'react-router-dom'
import HelmetComponent from 'components/Helmet'
import i18n from 'translation'


const { Title } = Typography;

const sortOrder = ['1_64', '1_61', '1_108', '1_107', '1_109', '1_56', '1_105', '1_103', '1_104', '1_102', '1_101']
const sortOrder2 = ['1_61', '1_55',  '1_100', '1_54', '1_96', '1_97', '1_98', '1_99']


const Timelines: React.FC = () => {

  const { t } = useTranslation()
  const navigate = useNavigate()

  const { isLoading, PointsTimelineTitle, PointsTimeline, allArrays, allArraysEn, refetch } = usePointsTimeline()

  const [myPointsTimelineTitle, setPointsTimelineTitle] = useState<TimelineTitle[]>([])

  useEffect(() => {
    setPointsTimelineTitle(PointsTimelineTitle)
  }, [PointsTimelineTitle])


  useEffect(() => {
   
    i18n.language == 'en' ? navigate('/timelineEn', { replace: false }) : navigate('/timeline', { replace: false })
   
  }, [i18n.language])


  const ProfileItem = (props: any) => {

    const [showMore, setShowMore] = useState<boolean>(false)

    let textFull =  props.cardDetailedText
    let textShort = `${props.cardDetailedText.substring(0, 3000)}`
    textShort = textShort.slice(0, textShort.lastIndexOf("&"))

    return (
      <div key={props.id} >
        <img style={{ width: "110%", marginLeft: '-10px' }} src={props.media.source.url} alt={props.title} />
        <h2 className={classes.timelineH2}>{props.cardTitle}</h2>
        <div className={classes.p} dangerouslySetInnerHTML={{ __html: showMore ? textFull : textShort + ' .... '   }} />
        <Button
          type="primary"
          ghost
          style={{ borderColor: "#808242", borderRadius: '8px', color: '#808242' }}
          className={classes.btn}
          onClick={() => setShowMore(!showMore)}
        >{showMore ? t("Δείτε λιγότερα") :  t("Δείτε περισσότερα ...") }
        </Button >
      </div>
    );
  };



  const ModalOpen = (props: any) => {

    const [open, setOpen] = useState(false);
    const [showMore, setShowMore] = useState<boolean>(false)

    let textFull =  props.cardDetailedText
    let textShort = `${props.cardDetailedText.substring(0, 3000)}`
    textShort = textShort.slice(0, textShort.lastIndexOf("&"))

    const showModal = () => {
      setOpen(true);
    };
  
    const hideModal = () => {
      setOpen(false);
    };

    return (
      <div key={props.id} >
        <div className={classes.pointTitle}>{props.cardTitle}</div>
        <img style={{ width: "110%", marginLeft: '-10px' }} src={props.media.source.url} alt={props.title} />
       
        <Button
          type="primary"
          ghost
          style={{ borderColor: "#808242", borderRadius: '8px', color: '#808242', marginLeft: '-2px'  }}
          className={classes.btn}
          onClick={showModal}
        >{t("Δείτε περισσότερα")}
        </Button >

        <Modal
          title={<Title level={4}>{props.cardTitle}</Title>} 
          centered
          width={1200}
          footer={false}
          open={open}
          onOk={hideModal}
          onCancel={hideModal}
        >
      
          <div className={classes.p} dangerouslySetInnerHTML={{ __html: !showMore ? textFull : textShort + ' .... ' }} /> 
          <Link to={`../lindos/${props.idMitos}`} className={classes.more}>{t("Περισσότερες πληροφορίες")}</Link> 
          
          <br /><br />
          
          <Image.PreviewGroup>
            <Row justify="center">
              {props.Images[0].url !== '' &&
                
                props.Images.map((image: any, index: number) => (
                <Col xs={12} lg={6} xl={6} key={index}>
                  <Image className={classes.hover} key={index} src={image.url} alt={image.alt} />
                </Col>
              ))}
            </Row>
          </Image.PreviewGroup>

        </Modal>

      </div>
    );
  };


  if (isLoading || myPointsTimelineTitle.length < 1) { return <Spinner /> }

  return (
    <>
      
      <HelmetComponent title={t('SEO Timeline title')} descrition={t('SEO Timeline description')} />
      
      <div style={{ width: "100%", marginTop: '80px', minWidth: '222px' }}>

        <Chrono
          items={myPointsTimelineTitle}
          mode="HORIZONTAL"
          cardWidth={400}
          cardHeight={346}
          itemWidth={460}
          lineWidth={4}
          borderLessCards={false}
          scrollable={{ scrollbar: true }}
          cardPositionHorizontal="BOTTOM"
          slideShow
          showAllCardsHorizontal
          theme={{
            primary: '#515124',
            secondary: 'white',
            cardBgColor: 'white',
            cardForeColor: 'black',
            titleColor: 'black',
            titleColorActive: '#515124',
          }}
          fontSizes={{
            cardSubtitle: '0.85rem',
            cardText: '0.8rem',
            cardTitle: '1.1rem',
            title: '1.1rem',
          }}
        >
     
          {/* {PointsTimeline.map((point: Timeline) => <ProfileItem key={point.id} {...point} /> )} */}
          
            {
              i18n.language == 'el' ? 
              allArrays.map((Timeline: any) => {

                return (
                  
                  Timeline.map((item: any) => {
                    return (
                      <div key={item.id}>
                        <h2 className={classes.timelineH2}>{item.title}</h2>
                        {item.data.sort((a: any, b: any) =>  item.title == 'Βυζαντινή περίοδος, 324 - 1309 μ.Χ.' ? sortOrder.indexOf(a.idMitos) - sortOrder.indexOf(b.idMitos) : item.title == 'Οθωμανική περίοδος, 1522 - 1912 μ.Χ.' ? sortOrder2.indexOf(a.idMitos) - sortOrder2.indexOf(b.idMitos) : '').map((it: any, index: any) => <ModalOpen key={it.id} {...it} />)}
                      </div>
                    )
                  })
                )
              })
              
              :
             
              allArraysEn.map((Timeline: any) => {
                return (
                  Timeline.map((item: any) => {
                    return (
                      <div key={item.id}>
                        <h2 className={classes.timelineH2}>{item.title}</h2>
                        {/* {item.data.map((it: any, index:any) => <ModalOpen key={it.id} {...it}  /> )} */}
                        {item.data.sort((a: any, b: any) =>  item.title == 'Βυζαντινή περίοδος, 324 - 1309 μ.Χ.' ? sortOrder.indexOf(a.idMitos) - sortOrder.indexOf(b.idMitos) : item.title == 'Οθωμανική περίοδος, 1522 - 1912 μ.Χ.' ? sortOrder2.indexOf(a.idMitos) - sortOrder2.indexOf(b.idMitos) : '').map((it: any, index: any) => <ModalOpen key={it.id} {...it} />)}
                      </div>
                    )
                  })
                )
              })
          }
        </Chrono>
      </div>
    </>
  )
}

export default Timelines
