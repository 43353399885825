import { useEffect, useState } from "react"
import { Icon } from "../Icon"
import { ScrollUpContainer } from "./styles"
import { getScroll } from "../utils/getWindow"
import { useLocation } from "react-router-dom"

const ScrollToTop = () => {
  
  const [showScroll, setShowScroll] = useState(false);
  const { pathname } = useLocation();
  const checkScrollTop = (event: any) => {
    const offsetFromTop = getScroll(event.target, true);

    if (!showScroll && offsetFromTop > 350) {
      setShowScroll(true);
    } else if (offsetFromTop <= 350) {
      setShowScroll(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener("scroll", checkScrollTop);
    return () => {
      window.removeEventListener("scroll", checkScrollTop);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const scrollUp = () => {
    const element = document.getElementById("home") as HTMLDivElement;
    element.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };

  return (
    <ScrollUpContainer onClick={scrollUp} show={showScroll}>
      <Icon src="scroll-top.svg" width="20px" height="20px" />
    </ScrollUpContainer>
  );
};

export default ScrollToTop