import React from "react"
import { Row, Col } from 'antd'
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next"
import classes from "./Footer.module.css"
import SearchClickComponent from "../Search/SearchClickComponent"


function Footer() {

  const { t } = useTranslation()

    return(
      <>
        <Row justify="center">
          <Col xs={24} sm={24} lg={24}>
            <div className={classes.footer_upper}>
              <ul className={classes.footer_menu1}>
                <li><a href="apps">{t("Εφαρμογές")}</a></li>
                <li><Link to='timeline'>{t("Χρονολόγιο")}</Link></li>
                <li><a href="mobile_app">{t("Mobile App")}</a></li>
                <li><SearchClickComponent item={ t("Αναζήτηση") } /></li>
                <li><a href='#address'>{t("Contacts")}</a></li>
              </ul>
              <ul className={classes.footer_menu2}>
                {/* <li><a href="#">{t("Terms and conditions")}</a></li> */}
                <li><Link to='page/privacy-policy' state={{ page: 'privacy-policy' }}>{t("Privacy Policy")}</Link></li>
                <li><Link to='page/gdpr' state={{ page: 'gdpr' }}>{t("Privacy")}</Link></li>
              </ul>
            </div>
          </Col>
        </Row>

        <Row justify="center" style={{ backgroundColor: '#feffe8ed' }}>
          <Col xs={24} sm={12} lg={6}>
            <div className={classes.footer_lower_1}>
              <Link to="/" className={classes.stylesA} >
                <img src="../img/logo_black.png" alt="folegandros logo" />
              </Link >
            </div>
          </Col>

          <Col xs={24} sm={12} lg={6}>
            <div className={classes.footerText_}><span className={classes.footer_bold}>{t("Διεύθυνση")}: </span> {t("Οδός Ιπποτών, 851 31 Μεσαιωνική Πόλη, Ρόδος")}</div>
          </Col>
          <Col xs={24} sm={12} lg={6}>
            <div className={classes.footerText}><span className={classes.footer_bold_phone}>{t("Τηλέφωνο")}: </span> +30 22413 65200</div>
          </Col>
          <Col xs={24} sm={12} lg={6}>
            <div className={classes.footerText}><span className={classes.footer_bold_email}>E-mail: </span> efadod@culture.gr</div>
          </Col>
        </Row>


        <Row justify="center">
          <div className={classes.footer_lower2}>
            <Col xs={24} sm={24} lg={24}>
              <img src="../img/sponsor.png" className="logoimg" alt="espa" />
            </Col>
          </div>
          <div className={classes.footer_lower2}>
            <Col xs={24} sm={24} lg={24}>
              <b>Powered by <a href="https://dotsoft.gr">Dotsoft</a></b>
            </Col>
          </div>
        </Row>
      </>
    )
}

export default Footer;