import React, {useState, useEffect, useRef, Suspense} from "react"
import { Tabs, Image, Carousel, Row, Col, Modal } from 'antd';
import classes from './styles.module.css'
import { useTranslation } from "react-i18next"
import { BarsOutlined, CameraOutlined, VideoCameraOutlined, PictureOutlined, ReadOutlined } from '@ant-design/icons'
import { SampleNextArrow, SamplePrevArrow } from './arrows'

import RouinsSVG from './../../../images/rouins'
import MonumentSVG from './../../../images/monument'

import ModelViewer from "./modelviewer";

const TabPane = Tabs.TabPane;
interface Props{
  Point: any 
  check: boolean
  threeD: string
  threeDMain: string
}


const ComponentTabs: React.FC<Props> = ({Point, check, threeD, threeDMain}: Props) =>  {

  const { t } = useTranslation()

  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }

  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
    }

  }, []);
  

  const isMobile = width <= 768;

  const settings = {
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  }


  const [hover, setHover] = useState<string>('#000');
  const [hover2, setHover2] = useState<string>('#000');

  const handleMouseEnter = () => {
    setHover('#B9BC66');
  };

  const handleMouseLeave = () => {
    setHover('#000');
  };

  const handleMouseEnter2 = () => {
    setHover2('#B9BC66');
  };

  const handleMouseLeave2 = () => {
    setHover2('#000');
  };

  return (
    <><br />
      <Tabs
        defaultActiveKey="1"
        centered
        tabBarStyle={{ color: 'black', outlineOffset: 'none' }}
        // items={[
        //   {
        //     label: <span style={{ fontSize: '36px', border: 'none' }}> <BarsOutlined /> </span>,
        //     key: '1',
        //     children:
        //     <div>
        //         <h2 className={classes.title}>{t("Περιγραφή")}</h2>
        //         {isMobile ?
        //           <div className={classes.p} dangerouslySetInnerHTML={{ __html: Point.description }} />
        //           :
        //           <div className={classes.p} dangerouslySetInnerHTML={{ __html: Point.description }} />
        //         }
        //     </div>,
        //   },
        //   {
        //     label: <span style={{ fontSize: '36px' }}> <PictureOutlined /> </span>,
        //     key: '2',
        //     children:
        //       <div>
        //         <h2 className={classes.title}>{t("Φωτογραφίες")}</h2>
        //         <Carousel effect="fade" arrows {...settings}>
        //           {Point.images.map((image: any, index:any) => {
        //             return (
        //               <div key={index}>
        //                 <h3 className={classes.contentStyle} style={{ backgroundImage: `url(${image.url})` }}></h3>
        //                 <div className={classes.contentImageDescription}>{image.caption}<br />
        //                   <p style={{ textAlign: 'center', paddingTop: '10px', fontWeight: '700', fontSize: '14px', fontStyle: 'italic' }}> Υπουργείο Πολιτισμού και Αθλητισμού - Εφορεία Αρχαιοτήτων Δωδεκανήσου</p>
        //                   <p style={{ textAlign: 'center', marginTop: '-14px', fontWeight: '700', fontSize: '14px', fontStyle: 'italic' }}> Hellenic Ministry of Culture and Sports - Ephorate of Antiquities of Dodecanese</p>
        //                 </div>
        //               </div>
        //             )
        //           })}
        //         </Carousel>
        //         {/* <Image.PreviewGroup>
        //         <Row justify="center">
        //           {Point.images.map((image: any) => {
        //             return (
        //               <Col key={image.id} xs={24} sm={6} lg={6} span={4}>
        //                 <Image style={{ padding: '4px' }} src={image.url} alt={image.alt} />
        //               </Col>
        //             )})}
        //         </Row>
        //       </Image.PreviewGroup> */}
        //       </div>,
        //   },
        //    { 
        //     label: check && <span className={classes.threeD}> 3d 360°</span>,
        //     key: '3',
        //     children:
        //     check &&
        //       <div>
        //         <h2 className={classes.title}>{t("Εικονικό Πανόραμα 360")}&#176;</h2>
        //         <div style={{ height: '600px' }}>
        //         <ModelViewer modelPath={"../threeD/stoa.glb"} />;
        //           {/* <Canvas camera={{ position: [0, 0, 5] }} >
        //           <pointLight position={[10, 10, 10]} />
        //             <directionalLight position={[9, 9.0, 9]}  />
        //             <primitive object={gltf.scene} position={[0, 0, 0]} children-0-castShadow />
        //             <OrbitControls target={[0, 1, 0]} />
        //           </Canvas> */}
        //         </div>
        //       </div>
        //   },

        //   {
        //     label: <span style={{fontSize: '36px'}}> <ReadOutlined /> </span>,
        //     key: '4',
        //     children:
        //     <div className={classes.biblio}>
        //       <h2 className={classes.title}>{t("Βιβλιογραφία")}</h2>
        //       <div className={classes.pBibliografy} dangerouslySetInnerHTML={{ __html: Point.bibliographic }} />
        //     </div>
        //   }
        // ]}
      >
        <TabPane
          tab={<span style={{ fontSize: '36px', border: 'none' }}> <BarsOutlined /> </span>}
          key="1">
            <div>
              <h2 className={classes.title}>{t("Περιγραφή")}</h2>
              {isMobile ?
              <div className={classes.p} dangerouslySetInnerHTML={{ __html: Point.description }} />
              :
              <div className={classes.p} dangerouslySetInnerHTML={{ __html: Point.description }} />
              }
            </div>
        </TabPane>

        <TabPane
          tab={<span style={{ fontSize: '36px' }}> <PictureOutlined /> </span>}
          key="2">
          <div>
            <h2 className={classes.title}>{t("Φωτογραφίες")}</h2>
              <Carousel effect="fade" arrows {...settings}>
                {Point.images.map((image: any, index:any) => {
                return (
                  <div key={index}>
                    <h3 className={classes.contentStyle} style={{ backgroundImage: `url(${image.url})` }}></h3>
                    <div className={classes.contentImageDescription}>{image.caption}<br />
                      <p style={{ textAlign: 'center', paddingTop: '10px', fontWeight: '700', fontSize: '14px', fontStyle: 'italic' }}> Υπουργείο Πολιτισμού και Αθλητισμού - Εφορεία Αρχαιοτήτων Δωδεκανήσου</p>
                      <p style={{ textAlign: 'center', marginTop: '-14px', fontWeight: '700', fontSize: '14px', fontStyle: 'italic' }}> Hellenic Ministry of Culture and Sports - Ephorate of Antiquities of Dodecanese</p>
                    </div>
                  </div>
                )
              })}
            </Carousel>           
          </div>
        </TabPane>
        {check &&
          <TabPane
            tab={<span onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} className={classes.threeD}> <RouinsSVG width={42} height={42} fill={hover} /></span>}
            key="3">
            <div>
              <h2 className={classes.title}>{t("Εικονικό Πανόραμα 360")}&#176;</h2>
              <div style={{ height: '600px' }}>
                <ModelViewer modelPath={threeD} />
              </div>
            </div>
          </TabPane>
        }
        {check && threeDMain &&
          <TabPane
            tab={<span onMouseEnter={handleMouseEnter2} onMouseLeave={handleMouseLeave2} className={classes.threeD}> <MonumentSVG width={42} height={42} fill={hover2} /></span>}
            key="3_1">
            <div>
              <h2 className={classes.title}>{t("Εικονικό Πανόραμα 360 ")}&#176; {t("ανακατασκευή")}</h2>
              <div style={{ height: '600px' }}>
                <ModelViewer modelPath={threeDMain} />
              </div>
            </div>
          </TabPane>
        }
        <TabPane
          tab={<span style={{fontSize: '36px'}}> <ReadOutlined /> </span>}
          key="4">
          <div className={classes.biblio}>
            <h2 className={classes.title}>{t("Βιβλιογραφία")}</h2>
            <div className={classes.pBibliografy} dangerouslySetInnerHTML={{ __html: Point.bibliographic }} />
          </div>
        </TabPane>

      </Tabs>
    </>
  )
}

export default ComponentTabs