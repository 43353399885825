import { LeftOutlined , RightOutlined  } from '@ant-design/icons'

export const SampleNextArrow = (props: any) => {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{
        ...style,
        color: '#21220a',
        fontSize: '52px',
        fontWeight: 'bold',
        lineHeight: '1.5715',
        marginRight: '80px',
        zIndex: '999'        
      }}
      onClick={onClick}
    >
      <RightOutlined />
    </div>
  )
}

export const SamplePrevArrow = (props: any) => {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{
        ...style,
        color: '#21220a',
        fontSize: '52px',
        fontWeight: 'bold',
        lineHeight: '1.5715',
        marginLeft: '60px',
        zIndex: '999'
      }}
      onClick={onClick}
    >
      <LeftOutlined />
    </div>
  )
}