import React, { useState, useRef, useEffect, useCallback, useMemo } from "react"
import { Row, Col, Button } from 'antd'
import classes from './styles.module.css'
import { usePoints, usePoints2 } from 'hooks/useMap'
import { PointsProps } from "services/props"
import { useLocation } from "react-router-dom";
import { Icon } from 'leaflet'
import { ZoomControl, ScaleControl, MapContainer, TileLayer, Marker, Popup, LayersControl, LayerGroup, useMapEvent, Tooltip } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import SvgPointer from "../../images/svg_pointers/index"
import Acropoli from "../../images/svg_pointers/Acropoli.svg"
import Oikismos from "../../images/svg_pointers/Oikismos.svg"
import Ypaifro from "../../images/svg_pointers/Ypaifro.svg"
import utils from '../../utils'
import Spinner from 'common/Spinner'
import HelmetComponent from 'components/Helmet'
import DrawerComponent from './Drawer/Drawer'
import { useTranslation } from "react-i18next"
import i18n from 'translation'
import i_am from "../Map/i_am.svg"
import { SearchOutlined } from '@ant-design/icons';

const iconClicked = new Icon({
  iconUrl: SvgPointer[1],
  popupAnchor: [0, -20],
  iconSize: [32, 32]
})

let SVG_Point = new Icon({
  iconUrl: Acropoli,
  popupAnchor: [0, -20],
  iconSize: [26, 26]
})

let SVG_Point_I_AM = new Icon({
  iconUrl: i_am,
  popupAnchor: [0, -20],
  iconSize: [40,40]
})

const Map: React.FC = () => {

  const { t } = useTranslation()

  const [iamLat, setIamLat] = useState<number>(0)
  const [iamLon, setIamLon] = useState<number>(0)
  const markerRef = useRef<any>(null)

  const { isLoading, Points, refetch } = usePoints()

  const location = useLocation()

  const mapRef = useRef<any>(null)

  const [myPoints, setMyPoints] = useState<PointsProps[]>([])

  const [radioValue, setRadioValue] = useState<undefined | string | number>(location?.state?.category)

  const [isId, setIsId] = useState<string>('')

  const [isClicked, setIsClicked] = useState<boolean>(false)

  const [myPointLatitude, setMyPointLatitude] = useState<string>()

  let name = t('Κανονικό')


  useEffect(() => {
    setMyPoints(Points)
  }, [Points])


  useEffect(() => {
    refetch()
  }, [i18n.language])



  useEffect(() => {
    let result: PointsProps[] = []
    result = utils.MapFilterPointers(Points, radioValue)
    setMyPoints(result)
    setMyPointLatitude('')
  }, [radioValue])


  useEffect(() => {

    if (location?.state?.id)
      showChildrenDrawer(location?.state?.id, location?.state?.lantitude)


    if (location?.state?.category) {
      let result: PointsProps[] = []
      result = utils.MapFilterPointers(Points, location?.state?.category)
      setMyPoints(result)
    }

  }, [Points, location])



  const showChildrenDrawer = (id: string, lantitude: string) => {
    setIsClicked(true)
    setIsId(id)
    setMyPointLatitude(lantitude)
  }



  const { coords } = usePoints2()




  useEffect(() => {
    if (coords) {
      setIamLat(coords.latitude)
      setIamLon(coords?.longitude) 
    }
  }, [coords])

  const eventHandlers = useMemo(() => ({
    dragend(e:any) {
      setIamLat(e.target.getLatLng().lat)
      setIamLon(e.target.getLatLng().lng)
    },
    
  }), [])


  const onFlyTo = () => {
    const map = mapRef.current;
    map.flyTo([iamLat, iamLon], 14);
  }


  const MarkerIam = useCallback(() => {
    // console.log(iamLat, iamLon)
    return<>
      <Marker
          eventHandlers={eventHandlers}
          icon={SVG_Point_I_AM}
          position={[iamLat, iamLon]}
          ref={markerRef}
        >
          {/* <Tooltip direction="top" offset={[0, -20]} opacity={1} permanent ><div className={classes.iamhere}>I am Here</div><br /></Tooltip> */}
        </Marker>
    </>
  }, [iamLat, iamLon])

  const MarkersPois = () => {
    return <>
      {
        myPoints.map((marker: PointsProps) => {

          SVG_Point = (marker.type === "Ακρόπολη" || marker.type === "Acropolis")
            ? new Icon({
              iconUrl: Acropoli,
              popupAnchor: [0, -20],
              iconSize: [22, 22]
            }) : (marker.type === "Οικισμός" || marker.type === "Settlement" )
              ? new Icon({
                iconUrl: Oikismos,
                popupAnchor: [0, -20],
                iconSize: [22, 22]
              }) : new Icon({
                iconUrl: Ypaifro,
                popupAnchor: [0, -20],
                iconSize: [22, 22]
              })

          return (
            <Marker
              key={marker.id}
              // icon={marker.lantitude == myPointLatitude ? iconClicked : icon}  
              icon={marker.lantitude === myPointLatitude ? iconClicked : SVG_Point}
              position={[parseFloat(marker.lantitude), parseFloat(marker.longitude)]}
              eventHandlers={{
                // click: () => showChildrenDrawer(marker.id, marker.lantitude),
                click: () => window.open(`../lindos/${marker.id}`, "_self"),
                mouseover: (e) => e.target.openPopup(),
                mouseout: (e) => e.target.closePopup()
              }}
            >

              <Popup >
                <div className={classes.gpsInfoWindow}>
                  <div className={classes.title}>{marker.title}</div>
                  <img src={marker.thumbnail} alt={marker.title} />
                </div>
              </Popup>
            </Marker>

          )
        })
      }
    </>
  }






  function SetViewOnClick(animateRef: any) {
    const map = useMapEvent('click', (e: any) => {
      map.setView(e.latlng, map.getZoom(), {
        animate: animateRef.current || false,
      })
    })

    return null
  }

  if (isLoading || myPoints.length < 1) return <Spinner />

  const MapContainerHead = () => {

    return (
      <>
        <MapContainer
          key={JSON.stringify([36.09132683539974, 28.08729093999769])} // To center every time
          center={[36.09132683539974, 28.08729093999769]}
          zoom={18}
          
          tap={false}
          style={{ height: '800px', width: '100%', zIndex: '0' }}
          ref={mapRef}
          zoomControl={false}
        >

          <ZoomControl position='bottomright' />
          <ScaleControl position="bottomright" metric imperial={false} />
          <SetViewOnClick animateRef={mapRef.current} />

          <MarkerIam />
          <MarkersPois />

          <LayersControl position="topright">

            <LayersControl.BaseLayer checked name={t('Δορυφόρος')}>
              <LayerGroup >
                <TileLayer maxZoom={19} url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.png" />
              </LayerGroup>
            </LayersControl.BaseLayer>

            <LayersControl.BaseLayer  name={name}>
              <LayerGroup >
                <TileLayer maxZoom={19} url="https://{s}.tile.openstreetmap.de/{z}/{x}/{y}.png" />
              </LayerGroup>
            </LayersControl.BaseLayer>

          </LayersControl>

        </MapContainer>
      </>
    )
  }


  return (
    <>

      <HelmetComponent title={t('SEO Map title')} descrition={t('SEO Map description')} />

      <div style={{ width: '100%', height: '800px' }}>
        <Row >
          <Col xs={24} lg={24} xl={24} >

            <DrawerComponent
              id={isId}
              isClicked={isClicked}
              radioValue={setRadioValue}
              category={location?.state?.category}
              points={myPoints}
              lantitudeValue={setMyPointLatitude}
              showMePlace = {onFlyTo}
            />

            <MapContainerHead />

            
          </Col>
        </Row>
      </div>
    </>
  )
}

export default Map
