import styled from "styled-components";
import { Link } from "react-router-dom";
import { MenuOutlined } from "@ant-design/icons";

export const HeaderSection = styled("header")`
  padding: 0.3rem 0.5rem;
  border-bottom: 2px solid #B9BC66;
  background-color: #26282E;
  font-family: 'Source Serif 4', serif;
  font-weight: 600;
  .ant-row-space-between {
    align-items: center;
    text-align: center;
  }

  span nav a, .anticon {
    color:white;
  } 

`;

 export const LogoContainer = styled(Link)`
    display: flex;
    color: white;
 `;


export const NavLink = styled("div")`
  display: inline-block;
  text-align: center;
`;

export const CustomNavLink = styled("div")`
  width: 203px;
  display: inline-block;
  @media only screen and (max-width: 411px) {
    width: 150px;
  }
  @media only screen and (max-width: 320px) {
    width: 118px;
  }
`;

export const ContactWrapper = styled("div")<any>`
  cursor: pointer;
  width: ${(p) => (p.width ? "100%" : "110px")};
  font-weight: 700;
  text-align: center;
  border-radius: 1.25rem;
  display: inline-block;
`;

export const Burger = styled("div")`
  @media only screen and (max-width: 890px) {
    display: block;
  }
  display: none;
  svg {
    fill: fff;
  }
`;

export const NotHidden = styled("div")`
  @media only screen and (max-width: 890px) {
    display: none;
  }
`;

export const Menus = styled("h5")`
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
`;

export const CustomNavLinkSmall = styled(NavLink)`
  font-size: 1.26rem;
  color: #0f0f0f;
  transition: color 0.1s ease-in;
  margin: 0.5rem 2.8rem;
  a {margin: 0rem}
  @media only screen and (max-width: 768px) {
    margin: 1.25rem 6rem;
    a {color: #0f0f0f !important;}
  }
`;

export const CustomNavLinkSmallMap = styled(NavLink)`
  font-size: 1.4rem;
  color: #0f0f0f;
  transition: color 0.1s ease-in;
  margin: 0.5rem 2.8rem;
 
  margin-left: 160px;
  margin-right: -100px;
  a {margin: 0rem;}

  @media only screen and (max-width: 768px) {
    margin: 2rem;
    a {color: #0f0f0f !important; }
  }
`;

export const CustomNavLinkSmallSearch = styled(NavLink)`
  font-size: 1.4rem;
  color: #0f0f0f;
  transition: color 0.1s ease-in;
  margin: 0.2rem 2.8rem;

  margin-left: 110px;
  margin-right: -100px;
  a {margin: 0rem;}

  @media only screen and (max-width: 768px) {
    margin: 2rem;
    a {color: #0f0f0f !important; }
  }
`;

export const CustomNavLinkSmallLanguage = styled(NavLink)`
  font-size: 1.4rem;
  color: #0f0f0f;
  transition: color 0.1s ease-in;
  margin: 0.5rem 2.8rem;
  margin-left: 110px;
  a {margin: 0rem;}

  @media only screen and (max-width: 768px) {
    margin: 2rem;
    a {color: #0f0f0f !important; }
  }
`;



export const Label = styled("span")`
  font-weight: 500;
  color: #404041;
  text-align: right;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

export const Outline = styled(MenuOutlined)<any>`
  font-size: 30px;
  text-align: center;
`;

export const Span = styled("span")`
  cursor: pointer;
  transition: all 0.3s ease-in-out;
`;



export const GeneralCover = styled('div')`
  height: 60px;
  display: inline-block;
  text-align: center;
  margin-top: 10px; 

@media only screen and (max-width: 690px) {
  margin-top: 0px; 
}
`;
