import React, { useEffect, useState } from "react"
import type { RadioChangeEvent } from 'antd';
import {  Select, Radio, Button, Drawer, Space, Divider  } from 'antd'
import { EnvironmentOutlined, AimOutlined} from '@ant-design/icons'
import classes from "./styles.module.css"
import { CloseOutlined, MenuOutlined, ArrowLeftOutlined } from '@ant-design/icons'
import ItemPois from './ItemPois'
import { useTranslation } from "react-i18next"
import { PointsProps } from "../../../services/props"
import Acropoli from "../../../images/svg_pointers/Acropoli.svg"
import Oikismos from "../../../images/svg_pointers/Oikismos.svg"
import Ypaifro from "../../../images/svg_pointers/Ypaifro.svg"
import i18n from "translation";



const { Option } = Select

interface Props{
  isClicked?: boolean
  id: string
  radioValue: (newRadioValue: string) => void
  category: string
  points: any
  lantitudeValue: (newLantitudeValue: string) => void
  showMePlace: ()=> void
}


const children: React.ReactNode[] = [];
for (let i = 10; i < 36; i++) {
  children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
}

const handleChange = (value: string) => {
  console.log(`selected ${value}`);
};


const DrawerComponent: React.FC<Props> = ({ id, isClicked, radioValue, category, points, lantitudeValue, showMePlace }: Props) => {

  const { t } = useTranslation()
 
  const [open, setOpen] = useState(true);
  const [value, setValue] = useState(category || 1);
  const [openChildDrawer, setOpenChildDrawer] = useState(isClicked);
  const [idSearch, setIdSearch] = useState(id);



  const showDrawer = () => setOpen(true)
  const onClose = () => setOpen(false)


  
  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
    radioValue(e.target.value)
  }

  useEffect(() => {
    if (id != '') {
      setOpen(false);
      setOpenChildDrawer(true)
    }
  }, [id])


  useEffect(() => {
    setIdSearch(id)
  }, [id])

  const onChildrenDrawerClose = () => {
    setOpen(true);
    setOpenChildDrawer(false)
  };

  const onChildrenDrawerOpen = (id:string, lantitude:string) => {
    setOpen(false)
    setOpenChildDrawer(true)
    setIdSearch(id)
    lantitudeValue(lantitude)
  };


  const ViewPoints = () => {

    return (
      <>
        {
          points.map((point: PointsProps) => {

            let SVG_Point = (point.type == "Ακρόπολη" || point.type == "Acropolis")
            ? Acropoli
            : (point.type == "Οικισμός") || (point.type == "Settlement")
            ? Oikismos
            : Ypaifro

            return (
              <div key={point.id} className={classes.content_rectangle_image_container} onClick={()=>onChildrenDrawerOpen(point.id, point.lantitude)}>

                <section>
                  <div>
                    <img src={SVG_Point} alt="category icon" width="32" />
                  </div>
                  <h3 title={point.title} className={ (point.type == "Ακρόπολη") || (point.type == "Acropolis")
                    ? classes.Acropoli
                    : (point.type == "Οικισμός") || (point.type == "Settlement")
                    ? classes.Oikismos
                      : classes.Ypaifro} >    {point.title.substring(0, 60)}</h3>
                  <div>
                    <p className={classes.content_rectangle_image_container}>
                      <span>{point.type}</span>
                    </p>
                  </div>
                </section>
                <img src={point.thumbnail} alt="image" />
              </div>
            )
          })}
      </>
    )

  }

  return (
    <>
      <Button type="primary" icon={<MenuOutlined />} onClick={showDrawer} size={'large'} className={classes.button}>
        {t('FILTERS')}

</Button>




      

      <Button
        type="primary"
        shape="circle"
        icon={<AimOutlined />}
        className={classes.buttonShowMyPlace}
        onClick={showMePlace}
      >{ t('Η θεση μου')}</Button>
      
      <Drawer
        autoFocus={false}
        mask={false}
        title={ 
        <>
        <span>{t('FILTERS')}</span>      
        <div>
          <Radio.Group onChange={onChange} value={value} className={classes.radioGroupItems} >
            <Space direction="vertical">
              <Radio value={1} style={{ fontSize: '16px' }}>{t('Όλα')}</Radio>
              <Radio value={i18n.language == 'el' ? 'Ακρόπολη' : 'Acropolis'} style={{ color: '#8E3E10', fontSize: '16px' }}>{t('Ακρόπολη')}</Radio>
              <Radio value={i18n.language == 'el' ? 'Οικισμός' : 'Settlement'} style={{ color: '#31555E', fontSize: '16px' }}>{t('Οικισμός')}</Radio>
              <Radio value={i18n.language == 'el' ? 'Ύπαιθρος' : 'Countryside'} style={{ color: '#3A5A2A', fontSize: '16px' }}>{t('Ύπαιθρος')}</Radio>
            </Space>
          </Radio.Group>
        </div>
        </>
        }
        placement={'left'}
        closable={false}
        onClose={onClose}
        open={open}
        getContainer={false}
        width={380}
        extra={
          <Space>
            <Button
              icon={<CloseOutlined />}
              onClick={onClose}
              type="text"
              style={{ position: 'absolute', top: 8, right: 0 }}
            />
          </Space>
        }
      >
        
        <Divider orientation="left"><EnvironmentOutlined />&nbsp;{ points.length} {t('Points')} </Divider>
        <div  className={classes.poisCategoriesItems__List} >
          <ViewPoints />
        </div>
 
      </Drawer>

      <Drawer
          mask={false}
          closeIcon={<ArrowLeftOutlined/>}
          title={ t('Description') }
          placement={'left'}
          closable={true}
          onClose={onChildrenDrawerClose}
          open={openChildDrawer}
          getContainer={false}
          style={{ position: 'absolute', scrollbarWidth: 'none'}}
          width={380}
          extra={
            <Space>
              <Button
                onClick={onChildrenDrawerClose}
                type="text"
                style={{ position: 'absolute', top: 8, right: 0 }}
              />
            </Space>
          }
        >
           
        <ItemPois id={idSearch} />
        
      </Drawer>
    </>
  )
}

export default DrawerComponent