import React, { useState } from "react"
import { Button, Modal, Image, Row, Col, Typography  } from 'antd'
import classes from "./styles.module.css"

interface Card{
  styleImage: boolean
  type: string
  image: string
  event: string
  description: string
  municipality: string
  start: string
  end: string
  t: any
}

const { Title } = Typography

const Card: React.FC<Card> = ({styleImage, type, image, event, description, start, end, t }:Card ) => {
 

  const [open, setOpen] = useState<boolean>(false);
  const [showMore, setShowMore] = useState<boolean>(false)

  const showModal = () => setOpen(true)

  const hideModal = () => setOpen(false)

  return (
  <>
    <div onClick={showModal} className={`${classes.calendarContent__container} ${classes.calendarContent__overlay__Grid}`}>
      <div className={classes.calendarCard}>
        <div className={classes.calendarCard__legend}>{type}</div>
        <div className={classes.calendarCard__imgBorder}>
          <img src={image} alt={event} /></div>
        <div className={classes.calendarCard__contentBorder}>
          <h1>{event}</h1>
          <div><p></p> </div>
          <div><span>{ start != '12/10/1983, 12:00' ? start != end ? start + ' - ' + end : start : ''} </span></div>
          <div className={classes.price}><span className={classes.priceSmall}>{t}</span></div>
        </div>
      </div>
    </div>

      

    <Modal
          title={<><Title level={4}>{event}</Title> <Title level={5} style={{color:'#265828'}}>{t}</Title></>} 
          centered
          width={1000}
          footer={false}
          open={open}
          onOk={hideModal}
          onCancel={hideModal}
        >
      
        <Row justify="center" >
          <Col xs={24} sm={10} lg={10}>
            <div className={classes.calendarCard__imgBorder2}>
              <img src={image} alt={event} />
            </div>
          </Col>
          <Col xs={24} sm={14} lg={14}>
            <div className={classes.container} dangerouslySetInnerHTML={{ __html: description }} ></div>
          </Col>
        </Row>


          {/* <div className={classes.p} dangerouslySetInnerHTML={{ __html: !showMore ? textFull : textShort + ' .... ' }} />  */}


      </Modal>
      



    </>
    // <div className={classes.container} onClick={onClick}>
    // "calendarContent__container__nJzJw calendarContent__overlay__Grid__fbJ9R"     
    //   <div className={`${classes.wrapper} ${styleImage ? classes.boxImage : ""}`} >
    //     <div className={`${classes.box1} ${styleImage ? classes.box1Image : ""}`} style={{ backgroundImage: `url(${image})` }} >
    //       <div className={classes.day} >{day}</div>
    //       <div className={classes.box}><span className={classes.span}>{type}</span></div>
    //       <div className={classes.month}>{month} ({year})</div> 
    //     </div>
    //     <div className={classes.event}>{event} </div>
    //     <div className={classes.municipality}>{municipality.toUpperCase()} </div>
    //     <div className={classes.hour}>{hour}</div> 
    //     <div className={classes.price}><span className={classes.priceSmall}>{t}</span></div>
    //   </div>
    // </div>

  )
}

export default Card
