import React, { Suspense, lazy } from "react"
import { Row, Col } from 'antd'
import classes from './styles.module.css'
import { useTranslation } from "react-i18next"
import Spinner from 'common/Spinner'
import HelmetComponent from 'components/Helmet'

const PointsCarousel = lazy(() => import('pages/Home/components/PointsCarousel'))
const CategoriesCarousel = lazy(() => import('pages/Home/components/CategoriesCarousel'))
const Applications = lazy(() => import('pages/Home/components/Applications'))
const Applications3D = lazy(() => import('pages/Home/components/Applications3D'))
const TimeLineHome = lazy(() => import('pages/Home/components/TimeLineHome'))
const MobileApp = lazy(() => import('pages/Home/components/MobileApp'))
const DigitalQuestBook = lazy(() => import('pages/Home/components/DigitalQuestBook'))
const Events = lazy(() => import('pages/Home/components/Events'))


const Home = () => {

  const { t } = useTranslation()

  return (
    <>
      
    <HelmetComponent title={t('SEO Home title')} descrition={t('SEO Home description')} />
      
    <Suspense fallback={<Spinner />}>
      <Row justify="end">
        <div className={classes.top_banner}>
          <Col xs={24} sm={12} lg={12}>
            <img src='./img/home_slider_img.png' alt='Επιλέγω ψηφιακό οδηγό περιήγησης' />
          </Col>
          <Col xs={24} sm={12} lg={12}>
            <div className={classes.top_banner_txt}>
              <h1>{t("I choose Lindos for")}</h1>
              <ul>
                <li>{t("Application of 1")}</li>
                <li>{t("Application of 2")}</li>
                <li>{t("Application of 3")}</li>
                <li>{t("Application of 4")}</li>
                <li>{t("Application of 5")}</li>
              </ul>
            </div>
          </Col>
        </div>
      </Row>
      
      <Row>
        <Col xs={24} sm={24} lg={24}>
          
          <div className={classes.divider}>
            <div className={classes.dividerBlock}></div>
            </div>
            
          <CategoriesCarousel />
            
          <PointsCarousel />
        
         
  
          <div className={classes.divider2}>
            <div className={classes.dividerBlock}></div>
          </div>

          <Applications />

          <Applications3D />

        </Col>
     
        <Col xs={24} sm={24} lg={24} style={{ backgroundColor: '#26282E' }}>
          
          <TimeLineHome />
 
          <MobileApp />

          <DigitalQuestBook />

          <Events />

        </Col>
      </Row>
    </Suspense>
  </>
  )
}

export default Home