import axios from 'axios'
import i18n from 'translation'
import { API_URL, API_KEY } from './configuration'
const API2 = 'https://lindos.mitos.cityguideplatform.com/api'
const X_API_KEY = '9jQ5ds4339NhPRw4kkkK'

axios.defaults.baseURL = API_URL

export const axiosSearch = {

  async getSearch(language: string, key: string) {

    // const { data } = await axios.get(`el/events/search?languageCode=${language}&keywords=${key}`, { headers: { 'API-KEY': API_KEY } })
    // const { data } = await axios.get(`https://lindos.mitos.cityguideplatform.com/api?languageCode=${language}&key=${key}`)

    const { data } = await axios.get(`${API2}/pois?lang=${language}&search_keyword=${key}`, {
      headers: {
        'X-API-KEY': X_API_KEY,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept,append,delete,entries,foreach,get,has,keys,set,values,Authorization",
        "Access-Control-Allow-Methods": "POST, GET, OPTIONS, DELETE, PUT"
      }
    })

    return data.success
  }
}