import React, { useRef } from "react";
import { useLoader, useFrame, useThree } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

const GltfModel = ({ modelPath }) => {
  const ref = useRef();
  const gltf = useLoader(GLTFLoader, modelPath);

  // Subscribe this component to the render-loop, rotate the mesh every frame
  useFrame(() => (ref.current.rotation.y += 0.0003));

  // Here, we can access the camera via the useThree hook
  useThree(({ camera }) => {
    camera.position.x = 120;
    camera.position.y = 60;
    camera.lookAt(40, 10, 10);
  });

  return (
    <>
      <primitive ref={ref} object={gltf.scene} scale={ modelPath == 'threeD/ippotiko_main.glb' ? "50" : "4"} />
    </>
  );
};

export default GltfModel;