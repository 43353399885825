
import React from "react"
import Props from './svgProps'

const MonumentSVG = ({width, height, fill}:Props) => {

    return (
    <svg
        width={width}
        height={height}
        viewBox="0 0 512 512"
        fill={fill} xmlns="http://www.w3.org/2000/svg">

		<path d="M504.396,471.797h-27.734v-16.395c0-4.199-3.405-7.604-7.604-7.604h-21.577v-16.394c0-4.199-3.405-7.604-7.604-7.604
			h-7.552l-7.9-22.194V201.69l9.528-22.914c0.008-0.02,0.016-0.041,0.025-0.06l8.366-20.12c2.986-1.026,5.135-3.852,5.135-7.186
			v-47.997c0-0.243-0.014-0.484-0.036-0.721c-0.001-0.014-0.001-0.029-0.002-0.044c-0.039-0.384-0.114-0.756-0.207-1.121
			c-0.015-0.058-0.028-0.116-0.045-0.173c-0.101-0.361-0.227-0.712-0.379-1.048c-0.02-0.045-0.043-0.087-0.063-0.131
			c-0.328-0.693-0.755-1.328-1.267-1.888c-0.046-0.05-0.088-0.1-0.136-0.149c-0.253-0.263-0.525-0.504-0.813-0.728
			c-0.055-0.043-0.109-0.083-0.165-0.124c-0.308-0.226-0.632-0.433-0.972-0.611c-0.012-0.006-0.021-0.015-0.033-0.021L259.485,1.843
			c-2.179-1.123-4.767-1.127-6.951-0.009L67.439,96.644c-0.014,0.007-0.025,0.017-0.04,0.024c-0.34,0.177-0.662,0.381-0.969,0.605
			c-0.057,0.042-0.114,0.083-0.169,0.127c-0.289,0.223-0.562,0.464-0.815,0.726c-0.047,0.048-0.088,0.097-0.133,0.147
			c-0.254,0.277-0.494,0.567-0.706,0.879l-0.001,0.001c-0.215,0.316-0.398,0.655-0.564,1.003c-0.022,0.047-0.046,0.092-0.068,0.14
			c-0.151,0.337-0.278,0.685-0.38,1.045c-0.016,0.058-0.029,0.116-0.045,0.173c-0.094,0.366-0.17,0.738-0.209,1.123
			c-0.001,0.009,0,0.018-0.001,0.027c-0.024,0.245-0.037,0.495-0.037,0.746v47.997c0,4.199,3.405,7.604,7.604,7.604h2.391
			l8.193,19.702c0.008,0.02,0.016,0.041,0.025,0.06l9.528,22.914v199.915l-7.9,22.194H70.906c-4.199,0-7.604,3.405-7.604,7.604
			v16.394H41.725c-4.199,0-7.604,3.405-7.604,7.604v16.395H7.604C3.405,471.796,0,475.201,0,479.4v23.998
			c0,4.199,3.405,7.604,7.604,7.604h496.792c4.199,0,7.604-3.405,7.604-7.604V479.4C512,475.202,508.595,471.797,504.396,471.797z
			 M421.887,168.186h-62.77l-3.815-9.173h70.399L421.887,168.186z M409.217,384.807v10.508h-37.431v-10.508H409.217z
			 M371.786,369.599V207.775h11.112v161.823H371.786z M398.107,369.599V207.775h11.111v161.823H398.107z M369.255,192.568
			l-3.815-9.173h50.122l-3.814,9.173H369.255z M369.547,410.522h41.909l4.726,13.278h-51.361L369.547,410.522z M276.449,384.807
			v10.508h-37.431v-10.508H276.449z M239.018,369.599V207.775h11.112v161.823H239.018z M265.338,369.599V207.775h11.112v161.823
			H265.338z M236.487,192.568l-3.815-9.173h50.122l-3.814,9.173H236.487z M236.779,410.522h41.909l4.726,13.278h-51.361
			L236.779,410.522z M226.349,168.186l-3.815-9.173h70.399l-3.814,9.173H226.349z M223.81,201.69v199.915l-7.9,22.194h-49.121
			l-7.9-22.194V201.69l9.528-22.914c0.008-0.02,0.016-0.041,0.025-0.06l8.193-19.702h29.427l8.193,19.702
			c0.008,0.02,0.016,0.041,0.025,0.06L223.81,201.69z M291.658,201.69l9.528-22.914c0.008-0.02,0.016-0.041,0.025-0.06l8.193-19.702
			h29.427l8.193,19.702c0.008,0.02,0.016,0.041,0.025,0.06l9.528,22.914v199.915l-7.9,22.194h-49.121l-7.9-22.194V201.69z
			 M255.989,17.151l152.549,78.656H102.429L255.989,17.151z M78.509,143.805v-32.789h353.764v32.789h-88.368h-39.574h-93.194
			h-39.574H78.509z M89.766,159.013h70.401l-3.815,9.173h-62.77L89.766,159.013z M143.682,384.807v10.508h-37.432
			c0,0,0-10.508,0-10.508H143.682z M106.251,369.599V207.775h11.112v161.823H106.251z M132.57,369.599V207.775h11.112v161.823
			H132.57z M103.72,192.568l-3.815-9.173h50.122l-3.814,9.173H103.72z M104.011,410.522h41.91l4.726,13.278H99.285L104.011,410.522z
			 M78.509,439.008h9.999h72.917h59.851h72.916h59.851h72.916h5.314v8.79H78.509V439.008z M49.329,463.006h21.576h368.972h21.577
			v8.791H49.329V463.006z M496.792,495.795H15.208v-8.79h26.518h427.332h27.734V495.795z"/>


		<path d="M320.569,69.279L259.08,37.575c-2.179-1.124-4.767-1.126-6.951-0.009L190.233,69.27c-3.126,1.602-4.749,5.136-3.926,8.551
			c0.823,3.415,3.879,5.821,7.392,5.821h123.385c3.509,0,6.563-2.401,7.39-5.81S323.687,70.887,320.569,69.279z M225.223,68.433
			l30.36-15.551l30.16,15.551H225.223z"/>


    </svg>
    )
}

export default MonumentSVG